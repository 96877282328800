
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import AuthService from "services/auth.service";

@Component
export default class App extends Vue {
  sidebarAnim = false;
  topbarAnim = false;
  shouldShowNavigation = false;


  created() {
    this.checkMenusAnimation();
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newVal: Route) {
    this.checkMenusAnimation(newVal);
  }

  private checkMenusAnimation(newPath?: Route) {
    let route = newPath ? newPath : this.$route;

    if (route.path == "/login" || route.path == "/inscription") {
      this.topbarAnim = this.sidebarAnim = this.shouldShowNavigation = false;
    } else {
      setTimeout(() => {
        this.topbarAnim = true;
      }, 100);

      setTimeout(() => {
        this.sidebarAnim = true;
      }, 500);
      this.shouldShowNavigation = true;
    }
  }
}
