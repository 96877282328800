

import { Component, Vue } from "vue-property-decorator";
import ListeAdministration from "components/Administration/ListeAdministration.vue";
import { mixins } from "vue-class-component";
import TrsBasicView from "views/BasicView.vue";

@Component({
  components: {
    ListeAdministration,
  },
})
export default class Accueil extends mixins(TrsBasicView) {

  title = "Administration";  
}
