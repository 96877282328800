
import { Component, Vue } from "vue-property-decorator";
import APIService from "services/api.service";
import Papa from 'papaparse';
import RegistrationForm from "models/RegistrationForm";
import authService from "services/auth.service";
import Taxonomy from 'models/Taxonomy';
import { mapGetters } from "vuex";
import { TaxonomyTerm } from "models/Taxonomy";
import { CompanyWebPage } from "models/Company";
import { CompanyContactInformation } from "models/CompanyForm";

export function formatPhone(phone){
  let ret = "";
  phone = phone.trim().replaceAll(' ', '').replaceAll('/','').replaceAll('.','').replaceAll('\'', '').replaceAll('(', '').replaceAll(')','').replaceAll('-', '').replace(/\D/g,'');
  if (phone[0] == '+'){
    if(phone[1] == 0 && phone.length == 13){
      phone = phone[0] + phone.slice(2,13);
    } else if(phone[3] == 0 && phone.length == 13){
      phone = phone.slice(0,3) + phone.slice(4,13);
    }
    if(phone.length == 12){
      ret = phone.slice(0, 3) + " " + phone.slice(3, 5) + " " + phone.slice(5, 8) + " " + phone.slice(8, 10) + " " + phone.slice(10, 12);
    }
  } else {
    let s = phone.slice(0, 2);
    if (s == "00"){
      if(phone.length == 13){
        ret = "+" + phone.slice(2, 4) + " " + phone.slice(4, 6) + " " + phone.slice(6, 9) + " " + phone.slice(9, 11) + " " + phone.slice(11, 13);
      }
    } else if ( s == "02" || s == "08" || s == "07" || s == "05" || s == "03" || s=="04"){
      if(phone.length == 10) {
        ret = "+41 " + phone.slice(1, 3) + " " + phone.slice(3, 6) + " " + phone.slice(6, 8) + " " + phone.slice(8, 10);
      }
    } else if ( s == "41") {
      if(phone.length == 11){
        ret = "+" + phone.slice(0, 2) + " " + phone.slice(2, 4) + " " + phone.slice(4, 7) + " " + phone.slice(7, 9) + " " + phone.slice(9, 11);
      }
    } else {
      if(phone.length == 9){
        ret = "+41 " + phone.slice(0, 2) + " " + phone.slice(2, 5) + " " + phone.slice(5, 7) + " " + phone.slice(7, 9);
      }
    }
  }
  if(ret == ""){
    console.debug("NO PHONE FOUND FOR : " + phone);
  }
  return ret;
}

export function formatDate(date) {
  let year = 0;
  let month = 0;
  let day = 0;
  let d = date.split('.');
  if (!(d[2] && d[1] && d[0])){
    d = date.split('-');
    if (!(d[2] && d[1] && d[0])){
      d = date.split('/');
      if (!(d[2] && d[1] && d[0])){
        console.debug("INVALID DATE : " + date);
        return null
      }
    }
  }
  console.log(d)
  if(parseInt(d[0], 10) == 0 && parseInt(d[1], 10) == 0 && parseInt(d[2], 10)==0){
    console.debug("INVALID DATE : " + date);
    return null
  }
  month = parseInt(d[1], 10)-1
  if(parseInt(d[0], 10) > 999){
    year = parseInt(d[0], 10)
    day = parseInt(d[2], 10)
  } else {
    if(parseInt(d[2], 10)< 22){
      d[2] = "20" + d[2];
    }
    year = parseInt(d[2], 10)
    day = parseInt(d[0], 10)
  }
  console.log(new Date(year, month, day))
  return new Date(year, month, day)
}

let globalData = new Vue({
  data: { $importTotal: 0,
          $importStep: 0,
          $importProg: 0,
          $userbase: [] }
});
Vue.mixin({
  computed: {
    $importTotal: {
      get: function () { return globalData.$data.$importTotal },
      set: function (newData) { globalData.$data.$importTotal = newData; }
    },
    $importStep: {
      get: function () { return globalData.$data.$importStep },
      set: function (newData) { globalData.$data.$importStep = newData; }
    },
    $importProg: {
      get: function () { return globalData.$data.$importProg },
      set: function (newData) { globalData.$data.$importProg = newData; }
    }
  }
})
@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    })
  }
})
export default class ImportCompanies extends Vue {

  importDialog = false;
  loadingDialog = false;

  csvSource = null;

  uploadDialog(){
    this.csvSource = null;
    this.importDialog = true;
  }
  requestUploadFile(){
      this.csvSource = this.$el.querySelector('#uploadmyfile')
  }

  refreshList() {
    this.$router.push('/').then(() => this.$router.push('administration/'));
  }

  getImportProgress() {
    if (globalData.$data.$importProg == globalData.$data.$importTotal && globalData.$data.$importTotal > 0){
      this.loadingDialog = false;
      this.refreshList();
      globalData.$data.$importProg = 0;
      globalData.$data.$importTotal = 0;
      globalData.$data.$importStep = 0;
    }
    return "Terminés : " + globalData.$data.$importProg + "/" + globalData.$data.$importTotal;
  }
  getImportPorcent() {
    let ret = "0";
    if (globalData.$data.$importTotal > 0){
      ret = parseFloat(""+(globalData.$data.$importStep / (5*globalData.$data.$importTotal))*100).toFixed(2) + "%";
    }
    return ret;
  }

  async importCSV(bigFile) {
    this.importDialog = false;
    this.loadingDialog = true;
    globalData.$data.$importTotal = 0;
    globalData.$data.$importProg = 0;
    globalData.$data.$importStep = 0;
    Papa.parse(this.csvSource.files[0], {
      worker: true,
      encoding: 'latin1',
      delimiter: ";;",
      step: async function(row) {
        globalData.$data.$importTotal += 1;
        let regForm = new RegistrationForm();
        // USER PART
        try {
            // Si mail companie :
            //user name : nom de la companie ou user+time
            let usernameAvailable = false;
            row.data[23] ? regForm.user.username = row.data[23].toLowerCase().replaceAll(' ', '').substr(0, 8) : regForm.user.username = "user" + Math.random().toString().substr(4,5);
            if(globalData.$data.$userbase.indexOf(regForm.user.username) == -1){
              usernameAvailable = true;
              globalData.$data.$userbase.push(regForm.user.username);
            }
            while(!usernameAvailable){
              regForm.user.username = regForm.user.username + Math.random().toString().substr(4,1);
              if(globalData.$data.$userbase.indexOf(regForm.user.username) == -1){
                usernameAvailable = true;
                globalData.$data.$userbase.push(regForm.user.username);
              }
            }
            regForm.user.password = Math.random().toString(36).slice(2) // random password : to be change on first login (with forgot password option)
            regForm.user.passwordConfirmation = regForm.user.password;
            regForm.user.last_name = regForm.user.username;
            regForm.user.first_name = regForm.user.username;
            if(row.data[39]){
              regForm.user.email = row.data[39];
            } else if(row.data[130]){
              regForm.user.email = row.data[130];
            } else if(row.data[154]){
              regForm.user.email = row.data[154];
            } else { // no mail are found...
              if(row.data[17] == "inactive"){
                //entreprise incative => ce n'est pas grave
                regForm.user.email = row.data[23].toLowerCase().replaceAll(' ', '').substr(0, 8).replaceAll(':', '').replaceAll('.', '').replaceAll(',', '') + "@trisinformatique.com";
              } else {
                //console.log("No Mail found... setting at " + row.data[23].toLowerCase().replaceAll(' ', '').substr(0, 8).replaceAll(':', '').replaceAll('.', '').replaceAll(',', '') + "@trisinformatique.com")
                regForm.user.email = row.data[23].toLowerCase().replaceAll(' ', '').substr(0, 8).replaceAll(':', '').replaceAll('.', '').replaceAll(',', '') + "@trisinformatique.com"
              }
            }
            // CONTACT PART
            let contacts = [];
            let contact = {
              add_str: "",
              add_zip: "",
              add_city: "",
              mobile: "",
              tel: "",
              mail: "",
              fax: "",
              lname: "",
              fname: "",
              posdir: true,
              poschi: true,
              positions: "",
              title: ""
            }
            let contact1 = JSON.parse(JSON.stringify(contact))
            let contact2 = JSON.parse(JSON.stringify(contact))
            let contact3 = JSON.parse(JSON.stringify(contact))
            contact1.add_str = row.data[131] ? row.data[131] : ""
            contact1.add_zip = row.data[137] ? row.data[137] : ""
            contact1.add_city = row.data[138] ? row.data[138] : ""
            contact1.mobile = row.data[142] ? formatPhone(row.data[142]) : ""
            contact1.tel = row.data[143] ? formatPhone(row.data[143]) : ""
            contact1.mail = row.data[130] ? row.data[130] : ""
            contact1.fax = row.data[144] ? formatPhone(row.data[144]) : ""
            contact1.lname = row.data[128] ? row.data[128] : ""
            contact1.fname = row.data[127] ? row.data[127] : ""
            contact1.posdir = row.data[149] ? row.data[149].includes("Directeur") : true
            contact1.poschi = row.data[149] ? row.data[149].includes("Chef du personnel") : true
            contact1.positions = row.data[149] ? row.data[149] : ""
            contact1.title = row.data[129] ? (row.data[129] == "mr") ? "M." : (row.data[129] == "mrs") ? "Mme" : (row.data[129] == "miss") ? "Mlle" : "" : ""
            contact2.add_str = row.data[155] ? row.data[155] : ""
            contact2.add_zip = row.data[160] ? row.data[160] : ""
            contact2.add_city = row.data[161] ? row.data[161] : ""
            contact2.mobile = row.data[165] ? row.data[165] : ""
            contact2.tel = row.data[166] ? formatPhone(row.data[166]) : ""
            contact2.mail = row.data[154] ? row.data[154] : ""
            contact2.fax = row.data[167] ? formatPhone(row.data[167]) : ""
            contact2.lname = row.data[152] ? row.data[152] : ""
            contact2.fname = row.data[151] ? row.data[151] : ""
            contact2.posdir = row.data[172] ? row.data[172].includes("Directeur") : true
            contact2.poschi = row.data[172] ? row.data[172].includes("Chef du personnel") : true
            contact2.positions = row.data[172] ? row.data[172] : ""
            contact2.title = row.data[153] ? (row.data[153] == "mr") ? "M." : (row.data[153] == "mrs") ? "Mme" : (row.data[153] == "miss") ? "Mlle" : "" : ""
            contact3.add_str = row.data[178] ? row.data[178] : ""
            contact3.add_zip = row.data[184] ? row.data[184] : ""
            contact3.add_city = row.data[185] ? row.data[185] : ""
            contact3.mobile = row.data[189] ? formatPhone(row.data[189]) : ""
            contact3.tel = row.data[190] ? formatPhone(row.data[190]) : ""
            contact3.mail = ""
            contact3.fax = row.data[191] ? formatPhone(row.data[191]) : ""
            contact3.lname = row.data[175] ? row.data[175] : ""
            contact3.fname = row.data[174] ? row.data[174] : ""
            contact3.posdir = row.data[196] ? row.data[196].includes('Directeur') : true
            contact3.poschi = row.data[196] ? row.data[196].includes('Chef du personnel') : true
            contact3.positions = row.data[196] ? row.data[196] : ""
            contact3.title = row.data[176] ? (row.data[176] == "mr") ? "M." : (row.data[176] == "mrs") ? "Mme" : (row.data[176] == "miss") ? "Mlle" : "" : ""
            contacts.push(contact1)
            contacts.push(contact2)
            contacts.push(contact3)
            if(row.data[130] || (!row.data[154] && row.data[128])){
              contact = contact1
            } else if (row.data[154] || row.data[152]){
              contact = contact2
            } else {
              contact = contact3
            }
            regForm.contact.address.street = contact.add_str
            regForm.contact.address.zip = contact.add_zip
            regForm.contact.address.city = contact.add_city
            regForm.contact.mobile = contact.mobile
            regForm.contact.telephone = contact.tel
            regForm.contact.email = contact.mail
            regForm.contact.fax = contact.fax
            regForm.contact.last_name = contact.lname
            regForm.contact.first_name = contact.fname
            regForm.contact.director = contact.posdir
            regForm.contact.chief = contact.poschi
            // COMPANY PART
            row.data[23] ? regForm.company.socialReason = row.data[23].trim().replace(/\w\S*/g, function(txt) {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : regForm.company.socialReason = "";
            row.data[62].includes("principal") ? regForm.company.siege = true : regForm.company.siege = false;
            regForm.company.address.street = row.data[118] ? row.data[118] + " " + row.data[117] : ""//+ " " + row.data[119] + " " + row.data[120]+ " " + row.data[121]: "";
            regForm.company.address.city = "Aigle";
            regForm.company.address.zip = "1860";
            regForm.company.telephone = row.data[40] ?  formatPhone(row.data[40]) : contact1.mobile ? contact1.mobile : contact1.tel ? contact1.tel : contact2.tel ? contact2.tel : contact3.tel ? contact3.tel :"";
            row.data[39] ? regForm.company.email = row.data[39] : regForm.company.email = "";
            row.data[42] ? regForm.company.fax = formatPhone(row.data[42]) : regForm.company.fax = "";
            let returnVal = await authService.register(regForm)
            globalData.$data.$importStep += 1;
            if(returnVal.status == 200){
              //FORM PART
              let company = await APIService.get('/companies/' + returnVal.data["id"])
              let tax_dat = await APIService.get('/taxonomies')
              let tax = Taxonomy.getTaxonomiesFromList(tax_dat.data);
              globalData.$data.$importStep += 1;
              if(company.status == 200){
                let form = company.data.latest_form;
                row.data[79] ? form.AVSno = row.data[79] : form.AVSno = null;
                row.data[3] ? form.description.BURNo = row.data[3] : form.description.BURNo = null;
                form.description.NOGANo = null;
                try{
                  let type_origin = row.data[28].trim().toLowerCase().replaceAll('é', 'e').replaceAll('à', 'a').replaceAll(' ', '_');
                  let tax_act = [];
                  for (let taxitem in tax){
                    if (tax[taxitem].key == "forme_juridique"){
                      tax_act = tax[taxitem].terms
                    }
                  }
                  let not_found = true;
                  let null_case = null;
                  for(let a in tax_act) {
                    if (type_origin == tax_act[a].value.toLowerCase()){
                      form.description.legalForm = tax_act[a];
                      not_found = false;
                    }
                    if (tax_act[a].value.toLowerCase() == "none"){
                      null_case = tax_act[a];
                    }
                  }
                  if(not_found){
                    console.log("Not found legal form : " + type_origin)
                    form.description.legalForm = null_case;
                  }
                } catch(error) {
                  console.debug("ERROR in legal form for " + row.data[28]);
                }
                row.data[27] ? form.description.activity.description = row.data[27] : form.description.activity.description = null;
                //row.data[51] ? form.description.activity.subTypes = row.data[51] : null;
                if (row.data[25]){
                  try{
                    let rows = row.data[25].split(",");
                    let tax_act = [];
                    for (let taxitem in tax){
                      if (tax[taxitem].key == "activites"){
                        tax_act = tax[taxitem].terms
                      }
                    }
                    for(let t in rows){
                      let not_found = true;
                      for(let a in tax_act) {
                        if (rows[t].toLowerCase().trim().replaceAll('é', 'e').replaceAll('-', ' ').replaceAll('ô', 'o').replaceAll('â', 'a').replaceAll('ê', 'e').replaceAll('   ', ' ').replaceAll('  ', ' ').replaceAll(' ', '_') == tax_act[a].value.toLowerCase().replaceAll('  ', ' ').replaceAll(' ', '_')) {
                          form.description.activity.types.push(tax_act[a]);
                          not_found = false;
                        }
                        if (rows[t] == "Kisoques" && tax_act[a].value == "kiosques_journaux") {
                          form.description.activity.types.push(tax_act[a]);
                          not_found = false;
                        }
                      }
                      if(not_found && rows[t] != "NULL"){
                        console.log("Not found activity : " + rows[t])
                      }
                    }
                  } catch(error) {
                    console.debug("ERROR in activity for " + row.data[25]);
                  }
                }
                if(row.data[29] == "1"){
                  form.description.cct.state = true;
                  row.data[30] ? form.description.cct.name = row.data[30] : null;
                }
                row.data[24] ? form.description.enseigne = row.data[24].replace(/\w\S*/g, function(txt) {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : form.description.enseigne = null;
                form.description.newsletterSub = row.data[31] == "1";
                //row.data[43] ? form.description.taxpayerNo = row.data[43] : form.description.taxpayerNo = null;
                if(row.data[17] == "inactive"){
                  row.data[46] && formatDate(row.data[46]) ? form.endOfActivity.date = formatDate(row.data[46]) : form.endOfActivity.date = formatDate('2021-12-13');
                  row.data[48] ? form.endOfActivity.description = row.data[48] : form.endOfActivity.description = "Inactive sur l'ancien TrisLine."
                  form.endOfActivity.cause = new TaxonomyTerm({label: "TAX.CAUSE_END_ACTIVITY.NONE.SELF", value: "none"})
                }
                row.data[87] ? form.familyAllowanceNo = row.data[87] : form.familyAllowanceNo = null;
                row.data[45] && formatDate(row.data[45]) ? form.municipalTerritory.activityStartDate = formatDate(row.data[45]) : form.municipalTerritory.activityStartDate = null;

                form.municipalTerritory.premisesSurface = row.data[43] ? parseInt(row.data[43]) : 0;
                form.municipalTerritory.shareCapital = row.data[44] ? parseInt(row.data[44]) : 0;
                form.municipalTerritory.address =  row.data[118] ? row.data[118] + " " + row.data[117] : ""
                form.municipalTerritory.forAddress = row.data[119] + " " + row.data[120]+ " " + row.data[121]
                //row.data[] ? form.municipalTerritory.websiteURL = row.data[] : form.municipalTerritory.websiteURL = null;
                form.rc_registration.date = (row.data[49] && formatDate(row.data[49])) ? formatDate(row.data[49]) : null;
                form.rc_registration.etat = row.data[19] == "1";
                form.rc_registration.numero = row.data[20] ? row.data[20] : null;
                //contact information :
                form.contacts = []
                contacts.forEach(c => {
                  let contact = new CompanyContactInformation();
                  contact.address.street = c.add_str
                  contact.address.zip = c.add_zip
                  contact.address.city = c.add_city
                  contact.telephone = c.tel
                  contact.email = c.mail
                  contact.fax = c.fax
                  contact.mobile = c.mobile
                  contact.first_name = c.fname
                  contact.last_name = c.lname
                  contact.title = c.title
                  c.poschi ? contact.functions.push("chef_du_personnel") : ""
                  c.posdir ? contact.functions.push("directeur") : ""
                  c.positions.includes('Administrateur') ? contact.functions.push("administrateur") : ""
                  c.positions.includes('Gérant') ? contact.functions.push("gerant") : ""
                  contact.other = c.positions.replace('Administrateur','').replace('Gérant','').replace('Chef du personnel','').replace('Directeur','').replaceAll('|', '').trim();
                  form.contacts.push(contact)
                });



                //form.siege.address.cit
                form.siege.address.country = row.data[37] ? row.data[37] : null;
                form.siege.address.street = row.data[34] ? row.data[34] : null;
                form.siege.address.zip = row.data[36] ? row.data[36] : null;
                form.siege.address.city = row.data[35] ? row.data[35] : null;
                form.siege.email = row.data[39] ? row.data[39] : null;
                form.siege.fax = row.data[42] ? formatPhone(row.data[42]) : null;
                //form.siege.mobile
                form.siege.sign = row.data[33] ? row.data[33] : null;
                form.siege.telephone = row.data[40] ? formatPhone(row.data[40]) : null;

                let card_empl = row.data[51] ? parseInt(row.data[51], 10) : 0; // employés totaux
                let card_empl_fulltime = row.data[66] ? parseInt(row.data[66], 10) : 0; //employés plein temps
                let card_empl_parttime = row.data[75] ? parseInt(row.data[75], 10) : 0; //employés temps partiel
                let card_empl_parttimeUp = row.data[69] ? parseInt(row.data[69], 10) : 0; //partiel +
                let card_empl_parttimeDown = row.data[72] ? parseInt(row.data[72], 10) : 0; //partiel -

                let card_fempl = row.data[52] ? parseInt(row.data[52], 10) : 0; //employés étrangers

                let card_empl_f = row.data[54] ? parseInt(row.data[54], 10) : 0; //employé femmes
                let card_empl_sf = row.data[59] ? parseInt(row.data[59], 10) : 0; //employés femmes suisses
                let card_empl_ff = row.data[58] ? parseInt(row.data[58], 10) : 0; //employés femmes étrangers
                let card_empl_fulltime_f = row.data[67] ? parseInt(row.data[67], 10) : 0; //plein temps femmes
                let card_empl_parttimeUp_f = row.data[70] ? parseInt(row.data[70], 10) : 0; //partiel + femmes
                let card_empl_parttimeDown_f = row.data[73] ? parseInt(row.data[73], 10) : 0; //partiel - femmes

                let card_empl_m = row.data[55] ? parseInt(row.data[55], 10) : 0;  //employés hommes
                let card_empl_sm = row.data[65] ? parseInt(row.data[65], 10) : 0; //employés hommes suisses
                let card_empl_fm = row.data[60] ? parseInt(row.data[60], 10) : 0; //employés hommes étrangers
                let card_empl_fulltime_m = row.data[68] ? parseInt(row.data[68], 10) : 0; //plein temps hommes
                let card_empl_parttimeUp_m = row.data[71] ? parseInt(row.data[71], 10) : 0; //partiel + hommes
                let card_empl_parttimeDown_m = row.data[74] ? parseInt(row.data[74], 10) : 0; //partiel - hommes

                let card_empl_adm = row.data[56] ? parseInt(row.data[56], 10) : 0; //employé admin
                let card_empl_prod = row.data[57] ? parseInt(row.data[57], 10) : 0; //employé prod

                let card_empl_perm_b = row.data[61] ? parseInt(row.data[61], 10) : 0; //employé permis b
                let card_empl_perm_c = row.data[62] ? parseInt(row.data[62], 10) : 0; //employé permis c
                let card_empl_perm_l = row.data[63] ? parseInt(row.data[63], 10) : 0; //employé permis l
                let card_empl_perm_o = row.data[64] ? parseInt(row.data[64], 10) : 0; //employé permis autres
                //check correctness of data :
                //Goes UP : sexe
              /* card_empl_m = Math.max(
                  card_empl_fulltime_m + card_empl_parttimeUp_m + card_empl_parttimeDown_m,
                  card_empl_fm + card_empl_sm,
                  card_empl_m);
                card_empl_f = Math.max(
                  card_empl_fulltime_f + card_empl_parttimeUp_f + card_empl_parttimeDown_f,
                  card_empl_ff + card_empl_sf,
                  card_empl_f);
                //Goes UP : work style
                card_empl_parttimeDown = Math.max(
                  card_empl_parttimeDown_f + card_empl_parttimeDown_m,
                  card_empl_parttimeDown);
                card_empl_parttimeUp = Math.max(
                  card_empl_parttimeUp_m + card_empl_parttimeUp_f,
                  card_empl_parttimeUp);
                card_empl_parttime = Math.max(
                  card_empl_parttimeUp + card_empl_parttimeDown,
                  card_empl_parttime);
                card_empl_fulltime = Math.max(
                  card_empl_fulltime_f + card_empl_fulltime_m,
                  card_empl_fulltime);
                //Goes UP : origin
                card_fempl = Math.max(
                  card_empl_perm_b + card_empl_perm_c + card_empl_perm_l + card_empl_perm_o,
                  card_empl_fm + card_empl_ff,
                  card_fempl);
                let card_sempl = card_empl_sm + card_empl_sf;
                //Goes UP All together
                card_empl = Math.max(
                  card_empl_m + card_empl_f,
                  card_empl_parttime + card_empl_fulltime,
                  card_fempl + card_sempl,
                  card_empl);
                //Goes Down:
                card_empl_f = card_empl - card_empl_m;
                card_empl_fulltime = card_empl - card_empl_parttime;
                card_sempl = card_empl - card_fempl;

                card_empl_ff = card_fempl - card_empl_fm;
                card_empl_perm_o = card_fempl - card_empl_perm_l - card_empl_perm_c - card_empl_perm_b;
                card_empl_fulltime_f = card_empl_fulltime - card_empl_fulltime_m;
                card_empl_parttimeUp = card_empl_parttime - card_empl_parttimeDown;
                card_empl_parttimeUp_f = card_empl_parttimeUp - card_empl_parttimeUp_m;
                card_empl_parttimeDown_f = card_empl_parttimeDown - card_empl_parttimeDown_m;*/

                form.workforce.administration = card_empl_adm;
                form.workforce.production = card_empl_prod;

                form.workforce.men.fulltime = card_empl_fulltime_m
                form.workforce.men.parttime = card_empl_parttimeUp_m
                form.workforce.men.parttime2 = card_empl_parttimeDown_m
                form.workforce.men.strangers = card_empl_fm
                form.workforce.men.swiss = card_empl_sm

                form.workforce.women.fulltime = card_empl_fulltime_f
                form.workforce.women.parttime = card_empl_parttimeUp_f
                form.workforce.women.parttime2 = card_empl_parttimeDown_f
                form.workforce.women.strangers = card_empl_ff
                form.workforce.women.swiss = card_empl_sf

                form.workforce.permits.B = card_empl_perm_b
                form.workforce.permits.C = card_empl_perm_c
                form.workforce.permits.L = card_empl_perm_l
                form.workforce.permits.other = (card_empl_perm_o >= card_fempl-card_empl_perm_b-card_empl_perm_c-card_empl_perm_l) ? card_empl_perm_o : card_fempl-card_empl_perm_b-card_empl_perm_c-card_empl_perm_l;

                form.validation_status = "validated";
                (row.data[91] && formatDate(row.data[91])) ? form.created_at = formatDate(row.data[91]) : console.log("no created date")

                let ret = await APIService.post("/companies/"+returnVal.data["id"]+"/forms/update", form)
                globalData.$data.$importStep += 1;
                if (ret.status == 201){
                  //WEB UPDATE
                  if(row.data[103] || row.data[101]){
                    let comp = await APIService.get('/companies/' + returnVal.data["id"])
                    if (comp.status == 200){
                      company = comp.data
                      company.webPage = new CompanyWebPage()
                      company.webPage.html = row.data[103]
                      if(row.data[101]) {
                        let complogo = row.data[101].replace("../../../ECOM/Trisline/../aigle/_users/", '').replaceAll("/", "").replaceAll("_", "")
                        company.webPage.logo = "https://aigle.trisline.ch/api/logo/" + complogo
                        console.log(company.webPage.logo)
                      }
                      await APIService.post("/companies/"+returnVal.data["id"]+"/update", company);
                    }
                  }
                  //Validating...
                  await APIService.post("/companies/" + returnVal.data["id"] + "/forms/adminValidate", form)
                  globalData.$data.$importStep += 1;
                } else {
                  console.debug("ERROR " + ret.status + " while updating form : " + ret.data);
                  globalData.$data.$importStep += 1;
                }
              } else {
                console.debug("ERROR " + company.status + " while getting company from base : " + company.data);
                globalData.$data.$importStep += 1;
              }
            } else {
              console.debug("ERROR " + returnVal.status + " while registring company : " + returnVal.data);
              globalData.$data.$importStep += 1;
            }
          globalData.$data.$importProg = globalData.$data.$importProg + 1;
          globalData.$data.$importStep += 1;
        } catch {
          globalData.$data.$importProg = globalData.$data.$importProg + 1;
        }
      },
      complete: function() {
        console.debug("Importation task created");
      }
    });
  }

}

