/* eslint-disable */
export interface IAddress{
    street   : string
    zip      : string
    city     : string
    country? : string
}

export class Address implements IAddress {
    street   : string
    zip      : string
    city     : string
    country? : string


    public constructor(object?: any){
        object       = object || {}
        this.street  = object.street  || ""
        this.zip     = object.zip     || ""
        this.city    = object.city    || ""
        this.country = object.country
    }
}

export interface IContactInfos{
    address     : Address
    mobile?     : string
    telephone   : string
    email       : string
    fax         : string
}

export class ContactInformation implements IContactInfos{
    address     : Address
    mobile?     : string
    telephone   : string
    email       : string
    fax         : string


    public constructor(object?: any){
        object       = object || {}
        this.address      = new Address(object.address)
        this.mobile       = object.mobile
        this.telephone    = object.telephone    || ""
        this.email        = object.email        || ""
        this.fax          = object.fax          || ""
    }
}