/* eslint-disable */
import { ContactInformation } from "./common";
import sanitizeHTML from "sanitize-html";
import CompanyForm from "./CompanyForm";
import User from "./User";

export default class Company {
    _id?            : string;

    latest_form?            : CompanyForm
    users?                  : User[]
    latest_validated_form?  : CompanyForm
    form                    : CompanyForm | undefined;

    website             : CompanyWebsiteInfo
    webPage             : CompanyWebPage
    //entrepriseEtablie: [],
    adminNotes?          : string


    created_at?     : string
    updated_at?     : string

    public constructor(object?: any){
        object                  = object || {}

        this.website              = new CompanyWebsiteInfo(object.website)
        this.webPage              = new CompanyWebPage(object.webPage)

        this.adminNotes           = object.adminNotes || ""

        this._id                = object._id;
        this.created_at         = object.created_at;
        this.updated_at         = object.updated_at;
        this.latest_form        = (object.latest_form ? new CompanyForm(object.latest_form) : object.latest_form)
        this.users              = (object.users ? User.getUsersFromList(object.users) : object.users)
        this.latest_validated_form = (object.latest_validated_form ?
                                      new CompanyForm(object.latest_validated_form) : object.latest_validated_form)

        this.form = (this.latest_validated_form ? this.latest_validated_form : this.latest_form);
    }

    public static getCompaniesFromList(objects: any): Company[]{
        if(objects && objects.length){
            return [...objects.map( (x:any) => new Company(x) )]
        }
        return []
    }

    public static getCompaniesFromCashedList(objects: any): Company[]{
        if(objects && objects.length){
            return [...objects.filter( (x:any) => x.public_cache ? true : false).map( (x:any) => new Company(x.public_cache))]

        }
        return []
    }

    public static getCompanyFromList(object: any): Company{
        if(object){
            return  new Company(object)
        }
        return null
    }
}

export class CompanyWebsiteInfo {
    exists      : boolean
    url         : string

    public constructor(object?: any){
        object       = object || {}

        this.exists       = object.exists || false
        this.url          = object.url || ""
    }
}

export class CompanyWebPage {
    logo    : string
    image   : string
    private _html    : string

    public constructor(object?: any){
        object       = object || {}

        this.logo     = object.logo || ""
        this.image    = object.image || ""
        this._html    = object._html || ""
    }

    get html(){ return this._html; }
    set html(code : string){
        this._html = sanitizeHTML(code);
    }
}
