
import { Component, Vue } from "vue-property-decorator";
import APIService from "services/api.service";
import Papa from 'papaparse';
import RegistrationForm from "models/RegistrationForm";
import authService from "services/auth.service";
import Taxonomy from 'models/Taxonomy';
import { mapGetters } from "vuex";
import { TaxonomyTerm } from "models/Taxonomy";
import { CompanyWebPage } from "models/Company";
import { CompanyContactInformation } from "models/CompanyForm";

let globalData = new Vue({
  data: { $importTotal: 0,
          $importStep: 0,
          $importProg: 0,
          $userbase: [],
          $companies: [],}
});
Vue.mixin({
  computed: {
    $importTotal: {
      get: function () { return globalData.$data.$importTotal },
      set: function (newData) { globalData.$data.$importTotal = newData; }
    },
    $importStep: {
      get: function () { return globalData.$data.$importStep },
      set: function (newData) { globalData.$data.$importStep = newData; }
    },
    $importProg: {
      get: function () { return globalData.$data.$importProg },
      set: function (newData) { globalData.$data.$importProg = newData; }
    },
    $companies: {
      get: function () { return globalData.$data.$companies },
      set: function (newData) { globalData.$data.$companies = newData; }
    }
  }
})
@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    }),
    ...mapGetters('companies', {
      companies: 'getAll',
      companyGetter: 'getOneByKey',
    }),
  }
})
export default class ImportCompaniesMail extends Vue {

  importDialog = false;
  loadingDialog = false;
  companies!: any; // Getter from vuex for all companies
  csvSource = null;

  uploadDialog(){
    this.csvSource = null;
    this.importDialog = true;
  }
  requestUploadFile(){
      this.csvSource = this.$el.querySelector('#uploadmyfile')
  }

  refreshList() {
    this.$router.push('/').then(() => this.$router.push('administration/'));
  }

  getImportProgress() {
    if (globalData.$data.$importProg == globalData.$data.$importTotal && globalData.$data.$importTotal > 0){
      this.loadingDialog = false;
      this.refreshList();
      globalData.$data.$importProg = 0;
      globalData.$data.$importTotal = 0;
      globalData.$data.$importStep = 0;
    }
    return "Terminés : " + globalData.$data.$importProg + "/" + globalData.$data.$importTotal;
  }
  getImportPorcent() {
    let ret = "0";
    if (globalData.$data.$importTotal > 0){
      ret = parseFloat(""+(globalData.$data.$importStep / (5*globalData.$data.$importTotal))*100).toFixed(2) + "%";
    }
    return ret;
  }

  async importCSV(bigFile) {
    this.importDialog = false;
    this.loadingDialog = true;
    globalData.$data.$importTotal = 0;
    globalData.$data.$importProg = 0;
    globalData.$data.$importStep = 0;
    APIService.get('/companies').then((res) => {
      globalData.$data.$companies = this.companies;
      console.log(globalData.$data.$companies)
      Papa.parse(this.csvSource.files[0], {
        worker: true,
        //encoding: 'latin1',
        delimiter: ";",
        step: async function(row) {
          if (row.data[1] && row.data[11]){
            globalData.$data.$importTotal += 1;
            let ok = false;
            let sr = row.data[1].trim().replace(/\w\S*/g, function(txt) {return txt.toLowerCase();})
            let companies = globalData.$data.$companies
            companies.forEach(comp => {
              if (comp.form.description.socialReason.toLowerCase() == sr || comp.form.description.enseigne.toLowerCase() == sr ){
                ok = true
                if (row.data[11] != comp.form.municipalTerritory.emailAddress){
                    //console.log(comp.form.municipalTerritory.emailAddress + " -> " + row.data[11])
                    comp.form.municipalTerritory.emailAddress = row.data[11];
                    APIService.post("/companies/"+comp._id+"/forms/update", comp.form).then((response) =>{
                      APIService.post("/companies/" + comp._id + "/forms/adminValidate", comp.form).then((respons) => {
                        globalData.$data.$importProg = globalData.$data.$importProg + 1;
                      })
                    })

                  //console.log(row.data[11])
                  //console.log(row.data[27])
                  /**/
                } else {
                  globalData.$data.$importProg = globalData.$data.$importProg + 1;
                }
              }
            });
            if(!ok){
              console.log("NOT OK : " + row.data[1])
              globalData.$data.$importProg = globalData.$data.$importProg + 1;
            }
          }
        },
        complete: function() {
          console.debug("Importation task created");
        }
      });
    })

  }

}

