import { AxiosResponse } from 'axios';
import { Store, Module} from 'vuex'
import APIService from './api.service';
import Mail from 'models/Mail';
import AuthService from "services/auth.service";

interface MailStoreIF {
    all: Mail[];
    latestFetch: Date;
}

export default class MailsService {
    static API_URL = 'https://aigle.trisline.ch/api';

    private store: Store<any>;
    private storeModule: Module<any, any> =  {
        namespaced : true,
        state: () =>({
            all : [],
            latestFetch : Date,
        }),
        mutations: {
            setMails(state: MailStoreIF, mails: Mail[]){
                state.all = [...mails];
            },
        },
        actions: {
            refresh(context: any){
                if(localStorage.getItem("user") && AuthService.userCan('admin')){
                    return APIService.get('/mails').then((response: AxiosResponse) =>{
                        if(response.status == 200){
                            console.debug("Successfully fetched Mails");
                            context.commit("setMails", Mail.getMailFromList(response.data))
                        }
                    });
                }
            },
        },
        getters: {
            getAll: (state: MailStoreIF) => {
                return state.all;
            },
        }
    }


    private constructor(store: Store<any>){
        store.registerModule('mails', this.storeModule);
        this.store = store;
        this.refresh();
    }

    public refresh(){
        return this.store.dispatch('mails/refresh')
    }

    static _instance: MailsService;

    public static init(store: Store<any>){
        if(!this._instance)
            this._instance = new MailsService(store);
        return this._instance;
    }

    public static get(store?: Store<any>): MailsService{
        if(!store && !this._instance){
            console.error("Trying to access Mails service's instance without init first and passing store !")
            throw "Trying to access Mail service's instance without init first and passing store !"
        } else if (this._instance){
            return this._instance;
        } else {
            return this.init(store!)
        }
    }
}
