import User from "models/User";
import APIService from "services/api.service";
import AuthService from "services/auth.service";
import { Store, Module} from 'vuex';
import { AxiosResponse } from 'axios';


interface UserStoreIF {
  all: User[];
  latestFetch: Date;
}

export default class UserService {
    static API_URL = 'https://aigle.trisline.ch/api';

    private store: Store<any>;
    private storeModule: Module<any, any> =  {
        namespaced : true,
        state: () =>({
            all : [],
            latestFetch : Date,
        }),
        mutations: {
            setUsers(state: UserStoreIF, users: User[]){
                state.all = [...users];
            },
        },
        actions: {
            refresh(context: any){
                if(localStorage.getItem("user") && AuthService.userCan('admin')){
                    return APIService.get('/users').then((response: AxiosResponse) =>{
                        if(response.status == 200){
                            context.commit("setUsers", User.getUsersFromList(response.data))
                        }
                    });
                }
            },
        },
        getters: {
            getOneByKey: (state: UserStoreIF) => (key: string) => {
                return state.all.find(elem => elem._id && elem._id.toLowerCase() == key.toLowerCase()) ??[];
            },
            getAll: (state: UserStoreIF) => {
                return state.all;
            },
        }
    }

    private constructor(store: Store<any>){
        store.registerModule('users', this.storeModule);
        this.store = store;
        this.refresh();
    }

    public refresh(){
        return this.store.dispatch('users/refresh')
    }

    static _instance: UserService;

    public static init(store: Store<any>){
        if(!this._instance)
            this._instance = new UserService(store);
        return this._instance;
    }

    public static get(store?: Store<any>): UserService{
        if(!store && !this._instance){
            console.error("Trying to access user service's instance without init first and passing store !")
            throw "Trying to access companies service's instance without init first and passing store !"
        } else if (this._instance){
            return this._instance;
        } else {
            return this.init(store!)
        }
    }
}

