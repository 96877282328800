
/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Component
 * Purpose  : Vue component that manages company workforce infos and
 *            is used to be validated by the Commune
 *************************************************************/

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { CompanyWorkforce } from "models/CompanyForm";
import { mapGetters } from "vuex";

@Component
export default class CompanyWorkforceFormComponent extends Vue {
  touched = false;

  /* ----- v-model ----- */
  @VModel({ type: CompanyWorkforce }) workforce!: CompanyWorkforce
  /* ------------------- */

  onTouched($event: boolean) {
    this.touched = $event;
    this.$emit("touched", this.workforce);
  }

  sendCancel() {
    this.$emit("cancel", true);
    this.touched = false;
  }

  sendSave() {
    this.$emit("save", this.workforce);
    this.touched = false;
  }
}
