
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import TrsBasicView from 'views/BasicView.vue';

@Component
export default class NewEmailView extends mixins(TrsBasicView) {

  title = "Emails - Envoyer un nouvel Email";
  
}
