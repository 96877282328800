
/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Component
 * Purpose  : Vue component that manages company legal infos and
 *            is used to be validated by the Commune
 *************************************************************/

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import Company from "models/Company";
import CompanyForm from "models/CompanyForm"
import { CompanyAssociate } from "models/CompanyForm";
//import Editor from '../../../components/Editor.vue';
import Editor from 'primevue/editor';
import APIService from "services/api.service";

@Component({
  components: {
    Editor,
  }
})
export default class FormulaireEntrepriseForm extends Vue {

  //@VModel({ type: CompanyForm }) companyForm!: CompanyForm
  @VModel({ type: Company }) company!: Company
  touched = false;
  needsDirector = true;
  needsChief = true;

  importDialog = false;
  logo_src = null;

  @Prop({
    default: true
  })
  showButtons!: boolean;

  /* ----- v-model ----- */
  @Prop()
  value!: Company;
  //value!: CompanyForm;

  @Watch("value", { immediate: true, deep: true })
  onInstanceChange(value: any) {
      this.checkContacts();
  }

  /* ------------------- */

  constructor() {
    super();
  }

  onTouched($event: any){
    this.touched = true;
    this.checkContacts();

    this.$emit("touched", this.touched);
    this.$emit("change", this.company.form)
    //this.$emit("change", this.companyForm)

  }

  sendCancel(){
    this.$emit("cancel", true);
    this.touched = false;
  }

  sendSave(){
    this.$emit("input", this.company);
    this.$emit("save", this.company);
    this.touched = false;
  }

  sendSaveWeb(){
    //this.company.webPage.html = this.content;
    this.$emit("input", this.company);
    this.$emit("saveWeb", this.company);
  }

  checkContacts(){
    if(this.company.form){//
      if(this.needsDirector && this.company.form.firstContactIsDirector()) {//
        this.company.form.removeDirector();//
      }

      if(this.needsChief && this.company.form.firstContactIsChief()) {//
        this.company.form.removeChief();//
      }
      this.needsDirector = !this.company.form.firstContactIsDirector();//
      this.needsChief = !this.company.form.firstContactIsChief();//
    }
  }

  addAssociate(){
    this.company.form.associates.push(new CompanyAssociate())//
    this.onTouched(true);
  }

  removeAssociate(index: number){
    this.company.form.associates.splice(index, 1);//
    this.onTouched(true);
  }

  addLogo(){
    this.importDialog = true;
  }
  confirmLogo(){
    this.importDialog = false;
    if(this.logo_src){
      const formData = new FormData();
      const config = {
                      headers: { 'Content-Type': 'multipart/form-data; charset=utf-8; boundary=---WebKitFormBoundary' + Math.random().toString().substr(2) }
                  }
      formData.append('logo', this.logo_src.files[0], 'file');
      formData.append('_id', this.company._id);
      APIService.post_withOptions("/companies/logo", formData, config).then( response => {
        if(response.status == 200){
          this.company.webPage.logo = "https://aigle.trisline.ch/api/logo/" + response.data.substr(response.data.lastIndexOf('/')+1, response.data.length);
        }
      });
      this.logo_src = null;
    }
  }
  requestUploadFile(){
    this.logo_src = this.$el.querySelector('#uploadmyfile')
  }
}

