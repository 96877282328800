
/* eslint-disable */
export default class User {
    _id?      : string;
    username  : string;
    password? : string;
    passwordConfirmation? : string;
    email     : string;
    first_name : string;
    last_name  : string;
    company_id : string;
    two_factor_status : boolean;

    public constructor(object?: any){
      object          = object || {}
      this.username   = object.username   || '';
      this.password   = object.password   || null;
      this.passwordConfirmation = object.passwordConfirmation || null;
      this.email      = object.email      || '';
      this.first_name  = object.first_name  || '';
      this.last_name   = object.last_name   || '';
      this.company_id = object.company_id || null;
      this.two_factor_status = object.two_factor_status || false;

      this._id = object._id || null;
    }

    public getInitials(): string{
      return (this.first_name && this.first_name != "" && this.last_name && this.last_name != "") ? this.first_name[0] + this.last_name[0] : "-";
    }

    public getFullname(): string{
      return "" + (this.first_name??"") + (this.first_name && this.first_name!="" ? " " : "") + (this.last_name??"");
    }

    public static getUsersFromList(objects: any): User[]{
      if(objects && objects.length){
          return [...objects.map( (x:any) => new User(x) )]
      }
      return []
    }


}
