
/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Component
 * Purpose  : Vue component that manages company contact infos and
 *            is used to be validated by the Commune
 *************************************************************/

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { CompanyContactInformation } from "models/CompanyForm";
import { mapGetters } from "vuex";
import { convert_to_snake_case } from "@/utils"

@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    })
  }
})
export default class CompanyContactInfoFormComponent extends Vue {

  @Prop({ type: CompanyContactInformation }) value!: CompanyContactInformation

  get contactInfo() {
    return this.value;
  }
  set contactInfo(desc: CompanyContactInformation) {
    if(!desc)
      desc = new CompanyContactInformation();

    if(this.enforcedFunction && this.enforcedFunction != ""){
      if(!desc.functions || desc.functions.length <= 0){
        desc.functions = [this.enforcedFunction]
      } else if(!desc.functions.includes(this.enforcedFunction)){
        desc.functions = [...desc.functions, this.enforcedFunction]
      }
    }

    this.$emit("input", desc);
  }


  @Watch("value", { immediate: true, deep: true })
  onInstanceChange(desc: any) {
      if(!desc)
        desc = new CompanyContactInformation();

      if(this.enforcedFunction && this.enforcedFunction != ""){
        if(!desc.functions || desc.functions.length <= 0){
          desc.functions = [this.enforcedFunction]
        } else if(!desc.functions.includes(this.enforcedFunction)){
          desc.functions = [...desc.functions, this.enforcedFunction]
        }
      }
       this.$emit("input", desc);
  }

  touched = false;

  @Prop()
  title!: string

  @Prop()
  enforcedFunction!: string

  /* ------------------- */

  onTouched($event: boolean) {
    this.touched = $event;
    this.$emit("touched", this.contactInfo);
  }

  sendCancel() {
    this.$emit("cancel", true);
    this.touched = false;
  }

  sendSave() {
    this.$emit("save", this.contactInfo);
    this.touched = false;
  }

  getCSS_Prefix(){
    return convert_to_snake_case(this.title);
  }
}
