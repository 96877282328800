

import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TrsBasicView from "views/BasicView.vue";


@Component
export default class StatView extends mixins(TrsBasicView) {

  title = "Statistique";
}
