import { AxiosResponse } from 'axios';
import { Store, Module} from 'vuex'
import APIService from './api.service';
import Company from 'models/Company';
import AuthService from "services/auth.service";

interface CompaniesStoreIF {
    all: Company[];
    pending: Company[];
    validated: Company[];
    latestFetch: Date;
}

export default class CompaniesService {
    static API_URL = 'https://aigle.trisline.ch/api';

    private store: Store<any>;
    private storeModule: Module<any, any> =  {
        namespaced : true,
        state: () =>({
            all : [],
            pending : [],
            //validated : [],
            latestFetch : Date,
        }),
        mutations: {
            setCompanies(state: CompaniesStoreIF, companies: Company[]){
                state.all = [...companies];
            },
            setPendingCompanies(state: CompaniesStoreIF, companies: Company[]){
                state.pending = [...companies];
            },
            /*setValidatedCompanies(state: CompaniesStoreIF, companies: Company[]){
                state.validated = [...companies];
            }*/
        },
        actions: {
            refresh(context: any){
                if(AuthService.userCan('admin')){
                    return APIService.get('/companiesFull').then((response: AxiosResponse) =>{
                        if(response.status == 200){
                            console.debug("Successfully fetched companies");
                            context.commit("setCompanies", Company.getCompaniesFromList(response.data))
                        }
                    });
                } else {
                    return APIService.get('/companies').then((response: AxiosResponse) =>{
                        if(response.status == 200){
                            console.debug("Successfully fetched companies");
                            context.commit("setCompanies", Company.getCompaniesFromCashedList(response.data))
                        }
                    });
                }
            },
        },
        getters: {
            getOneByKey: (state: CompaniesStoreIF) => (key: string) => {
                return state.all.find(elem => elem._id && elem._id.toLowerCase() == key.toLowerCase()) ??[];
            },
            getAll: (state: CompaniesStoreIF) => {
                return state.all;
            },
            /*getPublic: (state: CompaniesStoreIF) => {
                return state.validated;
            },*/
            getPending: (state: CompaniesStoreIF) => {
                return state.pending;
            }
        }
    }


    private constructor(store: Store<any>){
        store.registerModule('companies', this.storeModule);
        this.store = store;
        this.refresh();
    }

    public refresh(){
        //this.store.dispatch('companies/refreshValidated')
        //this.store.dispatch('companies/refreshPending')
        return this.store.dispatch('companies/refresh')
    }

    static _instance: CompaniesService;

    public static init(store: Store<any>){
        if(!this._instance)
            this._instance = new CompaniesService(store);
        return this._instance;
    }

    public static get(store?: Store<any>): CompaniesService{
        if(!store && !this._instance){
            console.error("Trying to access companies service's instance without init first and passing store !")
            throw "Trying to access companies service's instance without init first and passing store !"
        } else if (this._instance){
            return this._instance;
        } else {
            return this.init(store!)
        }
    }
}
