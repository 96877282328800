
/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Component
 * Purpose  : Vue component that manages company legal infos and
 *            is used to be validated by the Commune
 *************************************************************/

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch, ModelSync, VModel } from "vue-property-decorator";
import { CompanyDescription } from "models/CompanyForm";
import { mapGetters } from "vuex";
import { TaxonomyTerm } from "models/Taxonomy";
import AuthService from "services/auth.service";

@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    })
  }
})
export default class CompanyDescriptionFormComponent extends Vue {
  touched = false;
  taxonomies!: any; // Getter from vuex for all taxonomies

  /* ----- v-model ----- */

  @VModel({ type: CompanyDescription }) description!: CompanyDescription
  /*@Watch("value", { immediate: true, deep: true })
  onInstanceChange(value: any) {
    this.description = new CompanyDescription(value);
  }*/
  /* ------------------- */

  onTouched($event: boolean) {
    this.touched = $event;
    this.$emit("touched", this.touched);
  }

  sendCancel() {
    this.$emit("cancel", true);
    this.touched = false;
  }

  sendSave() {
    this.$emit("input", this.description);
    this.$emit("save", this.description);
    this.touched = false;
  }

  checkActivites(activite: any) {
    if(this.description.activity.types){
      return this.description.activity.types.filter((elem: any)=> {
        return elem.label.toLowerCase() == activite.label.toLowerCase()
      }).length == 0;
    }

    return true;
  }

  getTaxList(taxKey: string){
    return (this.taxonomies(taxKey) && this.taxonomies(taxKey).terms) ?
    this.taxonomies(taxKey).terms.map((elem: any)=>{
                              return {
                            label : elem.label,
                            value : elem.value,
                        }
    }) : []
  }

  test(val: any){
    this.onTouched(true)
  }

  getSubTaxList(taxKey: string){
    return (this.taxonomies(taxKey) && this.taxonomies(taxKey).terms) ?
    this.taxonomies(taxKey).terms.map((elem: any)=>{
        return {
          label     : elem.label,
          value     : elem.value,
          children  : elem.children.map((sub_elem: any)=>{
            return {
              label     : sub_elem.label,
              value     : sub_elem.value,
              parent    : elem.key
            }
          })
        }
    }) : []
  }

  only_admin(){
    if(AuthService.userCan('admin')){
      return true;
    }
    return false;
  }
}
