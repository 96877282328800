import axios from 'axios';
import authService from './auth.service';

export default class APIService {
  private static API_URL = 'https://aigle.trisline.ch/api';

  static getUrl(url: string): string{
    if(!url.startsWith("/")){
      url = "/"+ url;
    }
    return ( this.API_URL + url);
  }
  static get(route: string): Promise<any> {
    let options={headers : this.getHeaders()}
    return axios.get(this.getUrl(route), options);
  }

  static create(route: string, data: any): Promise<any>{
    let options={headers : this.getHeaders()}
    return axios.post(this.getUrl(route), data, options);
  }

  static post(route: string, data: any = {}): Promise<any>{
    let options={headers : this.getHeaders()}
    return axios.post(this.getUrl(route), data, options);
  }
  static post_withOptions(route: string, data: any = {}, options_sup: any): Promise<any>{
    let options={headers : this.getHeaders(options_sup)}
    return axios.post(this.getUrl(route), data, options);
  }

  static update(route: string, id: string, data: any): Promise<any>{
    let options={headers : this.getHeaders()}
    return axios.post(this.getUrl(route +'/' + id), data, options);
  }

  static updatePut(route: string, id: string, data: any): Promise<any>{
    let options={headers : this.getHeaders()}
    return axios.put(this.getUrl(route +'/' + id), data, options);
  }

  static delete(route: string, id: string): Promise<any>{
    let options={headers : this.getHeaders()}
    return axios.delete(this.getUrl(route +'/' + id), options);
  }

  private static getHeaders(options? : any){
    let headers;
    let auth = {Authorization: 'Bearer ' + authService.getToken()};

    if(options != undefined)
      headers = {...options.headers, ...auth}
    else
      headers = {...auth}

    return headers;
  }
}
