import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import Vuex from 'vuex'
import VueI18n from 'vue-i18n'


import JWT from 'jwt-decode';
import TaxonomiesService from './services/taxonomies.service'
import LocalesService from './services/locales.service'

import "@/shared/usedPrimeComponents"
import "@/shared/componentsDeclaration"
import NavigationService from 'services/nav.service'
import CompaniesService from 'services/companies.service'
import PrimeVue from 'primevue/config';
import UserService from 'services/user.service'
import MailTemplatesService from 'services/mailTemplates.service'
import MailsService from 'services/mails.service'

Vue.use(PrimeVue, {
  locale: {
    accept: 'Oui',
    reject: 'Non',
    choose: 'Choisir',
    upload: 'Upload',
    cancel: 'Annuler',
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["Di","Lu","Ma","Me","Je","Ve","Sa"],
    monthNames: ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"],
    monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin","Juil", "Août", "Sep", "Oct", "Nov", "Dec"],
    today: 'Aujourd\'hui',
    weekHeader: 'Sem',
    firstDayOfWeek: 1,
    dateFormat: 'dd/mm/yy',
}
})

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(Vuex);
Vue.use(VueI18n)


Vue.component('JWT', JWT);


const store = new Vuex.Store({
  state : {
    app : {}
  }
})

const i18n = new VueI18n({
  locale          : 'fr', // set locale
  fallbackLocale  : 'fr',
  messages        : {}, // set locale messages
})

TaxonomiesService.init(store);
LocalesService.init(store, i18n);
NavigationService.init(store);
CompaniesService.init(store);
UserService.init(store);
MailTemplatesService.init(store);
MailsService.init(store);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
