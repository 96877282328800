
import { Component, Vue, Watch } from "vue-property-decorator";
import Mail from 'models/Mail';
import MailTemplate from 'models/MailTemplate';
import Company from "models/Company";
//import Editor from '../../../components/Editor.vue';
import Editor from 'primevue/editor';
import { mapGetters} from 'vuex';
import APIService from "services/api.service";

@Component({
  computed: {
    ...mapGetters('templates', {
      templates: 'getAll',
    }),
    ...mapGetters('companies', {
      companies: 'getAll'
    }),
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    })
  },
  components: {
    Editor,
  }
})
export default class EmailForm extends Vue {
  mail = new Mail();
  deList = ["fichier@aigle.ch"];
  templates!: any; // Getter from vuex for all companies
  companies!: any;
  taxonomies!: any;

  selectedTemplate = new MailTemplate();
  manageTemplatesDialog = false;

  piece_jointe_src = null;
  importDialog = false;

  companies_filtered = [];
  //mail pour liste entreprise
  mailToOptions = [];
  mailToName = [];
  //mail pour catégories
  mailToCat = [];
  //mail manuels
  mailToNewList = [];
  mailToNew = "";
  //filtrer les mail pour actif seulement
  mailToActiveFilter = false;
  // filtre pour la mise à jour des formulaires
  mailUpdateFormFilter = false;
  mailUpdateFormDate = null;
  //filtre pour les status d'entreprise
  mailStatusFilter = false;
  mailStatusElem = [];
  mailStatusOptions = [
    {
      value : "validated",
      label : "Validée",
    },
    {
      value : "pending_validation",
      label : "En attente",
    },
    {
      value : "created",
      label : "Brouillon",
    }
  ];
  //mail cc pour liste entreprise
  mailToCC = [];
  //mail cc pour catégories
  mailToCatCC = [];
  //mail cc manuels
  mailToNewListCC = [];
  mailToNewCC = "";

  //mail bcc pour liste entreprise
  mailToBCC = [];
  //mail bcc pour catégories
  mailToCatBCC = [];
  //mail bcc manuels
  mailToNewListBCC = [];
  mailToNewBCC = "";



  color1 = "#3f3f3f";
  color2 = "#f7f7f7";
  color3 = "#5667bf";

  optionBoxActiveColor = this.color1;
  optionBoxUpdateColor = this.color1;
  optionBoxStatusColor = this.color1;
  optionBoxActiveBackColor = this.color2;
  optionBoxUpdateBackColor = this.color2;
  optionBoxStatusBackColor = this.color2;

  loading = true;

  created(){
    this.getCompanyList(null);
    if(this.companies.length > 0){
      this.loading = false;
    }
  }

 /*  beforeUpdate () {
    this.getCompanyList(null);
  }
 */
  @Watch('companies')
  onPropertyChanged(value: any, oldValue: any) {
    this.getCompanyList(null);
    this.loading = false;
  }

  sendMail(){
    if(!this.mail.from || this.mail.from == ""){
      this.$alert("Merci de renseigner l'expéditeur");
    } else {
      if(!this.mail.to || this.mail.to.length == 0 || (this.mail.to.length == 1 && this.mail.to[0] == "")){
        this.$alert("Merci de renseigner le destinataire");
      } else {
        if(!this.mail.object || this.mail.object == ""){
          this.$alert("Merci de renseigner l'objet du mail");
        } else {
          if(!this.mail.content || this.mail.content == ""){
            this.$alert("Le corps du mail est vide");
          } else {
            const config = {
                    headers: { 'Content-Type': 'multipart/form-data; charset=utf-8; boundary=---WebKitFormBoundary' + Math.random().toString().substr(2) }
                }
            const formData = new FormData();
            formData.append('from', this.mail.from);
            for (let i = 0; i < this.mail.to.length; i++) {
                formData.append('to[]', this.mail.to[i]);
            }
            for (let i = 0; i < this.mail.cc.length; i++) {
                formData.append('cc[]', this.mail.cc[i]);
            }
            for (let i = 0; i < this.mail.bcc.length; i++) {
                formData.append('bcc[]', this.mail.bcc[i]);
            }
            for (let i = 0; i < this.mail.piecesJointes.length; i++) {
                formData.append('piecesJointes[]', this.mail.piecesJointes[i], 'file'+i);
                formData.append('pieceJointeName[]', this.mail.piecesJointes[i].name);
            }
            formData.append('object', this.mail.object);
            formData.append('content', this.mail.content);
            APIService.post_withOptions("/mails/new", formData, config).then(() => {
              this.$alert("Votre message a bien été envoyé !");
              this.resetForm();
            });
          }
        }
      }
    }
  }

  resetForm(){
    this.mail = new Mail();
    //mail pour liste entreprise
    this.mailToOptions = [];
    this.mailToName = [];
    //mail pour catégories
    this.mailToCat = [];
    //mail manuels
    this.mailToNewList = [];
    this.mailToNew = "";

    //mail cc pour liste entreprise
    this.mailToCC = [];
    //mail cc pour catégories
    this.mailToCatCC = [];
    //mail cc manuels
    this.mailToNewListCC = [];
    this.mailToNewCC = "";

    //mail bcc pour liste entreprise
    this.mailToBCC = [];
    //mail bcc pour catégories
    this.mailToCatBCC = [];
    //mail bcc manuels
    this.mailToNewListBCC = [];
    this.mailToNewBCC = "";
  }

  saveTemplate(){
    this.$prompt("Merci de donner un nom au template : ").then(text => {
        let newTemplate = new MailTemplate();
        newTemplate.content = this.mail.content;
        newTemplate.name = text;
        this.templates.push(newTemplate);
        APIService.post("/mails/templates/new", newTemplate);
      });
  }
  addTemplateToText(){
    this.mail.content += this.selectedTemplate.content;
  }
  manageTemplates(){
    this.manageTemplatesDialog = true;
  }
  updateTemplate(){
    this.templates.forEach(item => {
      if (item.name == this.selectedTemplate.name){
        item.content = this.selectedTemplate.content;
        APIService.post("/mails/templates/update/"+item._id, item);
      }
    });
  }
  deleteTemplate(){
    this.$confirm("Voullez-vous vraiment supprimer ce template ?", "Action irréversible", "warning").then(() => {
      APIService.get("/mails/templates/delete/"+this.selectedTemplate._id);
      this.templates.forEach(item => {
        if (item.name == this.selectedTemplate.name){
          this.templates.splice(this.templates.indexOf(item), 1);
        }
      });
      this.selectedTemplate = new MailTemplate();
    });
  }

  addPieceJointe(){
    this.piece_jointe_src = null;
    this.importDialog = true;
  }
  deletePieceJointe(name){
    this.mail.piecesJointes.forEach(item => {
      if (item.name == name){
        this.mail.piecesJointes.splice(this.mail.piecesJointes.indexOf(item), 1);
      }
    })
  }
  confirmPieceJointe(){
    this.importDialog = false;
    this.mail.piecesJointes.push(this.piece_jointe_src.files[0]);
    this.piece_jointe_src = null;
  }
  requestUploadFile(){
    this.piece_jointe_src = this.$el.querySelector('#uploadmyfile')
  }

  //récupère liste de catégories
  getTaxList(taxKey: string){
    return (this.taxonomies(taxKey) && this.taxonomies(taxKey).terms) ?
    this.taxonomies(taxKey).terms.map((elem: any)=>{
                              return {
                            label : elem.label,
                            value : elem.value,
                        }
    }) : []
  }

  //trie les companies actives / non-actives et par date de mise à jour du formulaire
  getCompanyList(event: any){
    if(event){
      let targetId = event.currentTarget.id;
      if(targetId == "option-box-active"){
        this.mailToActiveFilter = !this.mailToActiveFilter;
      } else if (targetId == "option-box-update"){
        this.mailUpdateFormFilter = !this.mailUpdateFormFilter;
      } else if (targetId == "option-box-status"){
        this.mailStatusFilter = !this.mailStatusFilter;
      }
    }
    this.companies_filtered = this.companies.filter(comp => {
        //filtrage basé sur newsletterSub
        if (!comp.form.description.newsletterSub) {
          return false;
        }

      //filtre les actifs
      return (this.mailToActiveFilter) ? (comp.form && comp.form.endOfActivity && !(comp.form.endOfActivity.cause.label)) : true;
    }).filter(comp => {
      //filtre les dates de mises à jours :
      return (this.mailUpdateFormFilter && this.mailUpdateFormDate) ? (comp.form && comp.form.updated_at && comp.form.updated_at && (new Date(this.mailUpdateFormDate) > new Date(comp.form.updated_at))) : true;
    }).filter(comp => {
      //filtre les status d'entreprise :
      return (this.mailStatusFilter) ? (comp.form && comp.form.validation_status && (this.mailStatusElem.indexOf(comp.form.validation_status) != -1)) : true;
    });
    this.getEmailList();
    this.updateMailList();
  }

  //récupère liste pour choix par entreprise
  getEmailList(){
    this.mailToOptions = [];
    let i = 0;
    this.companies_filtered.map((elem: Company)=>{
      let test = true;
      if(elem.form && elem.form.municipalTerritory && elem.form.municipalTerritory.emailAddress && elem.form.description && elem.form.description.socialReason){
        this.mailToOptions.map(function(mailElem) {
          if(mailElem.label == elem.form.description.socialReason || mailElem.value == elem.form.municipalTerritory.emailAddress) {
            test = false;
          }
        })
        if(test){
          this.mailToOptions.push(  {
                                key :   i,
                                label : elem.form.description.socialReason,
                                value : elem.form.municipalTerritory.emailAddress,
                              })
        }
        i+=1;
      }
    })
    let temp = this.mailToName
    this.mailToName = []
    temp.map( (elem) => {
      this.mailToOptions.filter( (mail) => {
        if (mail.label == elem.label) {
          this.mailToName.push(mail)
          return true
        }
      })
    })
  }

  addMail() {
    let test = true;
    this.mailToNewList.map((elem: any) =>{
      if(this.mailToNew == elem.value) {
        test = false;
      }
    });
    if(test){
      const mail = {
        label: this.mailToNew,
        value: this.mailToNew
      }
      this.mailToNewList.push(mail)
      this.updateMailList();
    }
  }
  //pour CC
  addMailCC() {
    let test = true;
    this.mailToNewListCC.map((elem: any) =>{
      if(this.mailToNewCC == elem.value) {
        test = false;
      }
    });
    if(test){
      const mail = {
        label: this.mailToNewCC,
        value: this.mailToNewCC
      }
      this.mailToNewListCC.push(mail)
      this.updateMailListCC();
    }
  }
  //pour BCC
  addMailBCC() {
    let test = true;
    this.mailToNewListBCC.map((elem: any) =>{
      if(this.mailToNewBCC == elem.value) {
        test = false;
      }
    });
    if(test){
      const mail = {
        label: this.mailToNewBCC,
        value: this.mailToNewBCC
      }
      this.mailToNewListBCC.push(mail)
      this.updateMailListBCC();
    }
  }

  updateMailList(){
    this.mail.to = [];
    //récupération des catégories
    this.mailToCat.map((type: any)=>{
      this.companies_filtered.map((elem: Company)=>{
        let test = true;
        if(elem.form && elem.form.municipalTerritory && elem.form.municipalTerritory.emailAddress &&
        elem.form.description && elem.form.description.activity && elem.form.description.activity.types) {
          elem.form.description.activity.types.forEach(value => {
            if(value.label == type.label || value.value == type.value){
              if(this.mail.to.indexOf(elem.form.municipalTerritory.emailAddress) === -1) {
                this.mail.to.push(elem.form.municipalTerritory.emailAddress);
              }
            }
          })
        }
      })
    })
    //récupération des adresse mails connues
    this.mailToName.map((elem: any) =>{
      if(this.mail.to.indexOf(elem.value) === -1) {
        this.mail.to.push(elem.value);
      }
    });
    //récupération des adresses mails manuelles
    this.mailToNewList.map((elem: any) =>{
      if(this.mail.to.indexOf(elem.value) === -1) {
        this.mail.to.push(elem.value);
      }
    });
  }
  updateMailListCC(){
    this.mail.cc = [];
    //récupération des catégories
    this.mailToCatCC.map((type: any)=>{
      this.companies_filtered.map((elem: Company)=>{
      let test = true;
      if(elem.form && elem.form.municipalTerritory && elem.form.municipalTerritory.emailAddress &&
          elem.form.description && elem.form.description.activity && elem.form.description.activity.types) {
            elem.form.description.activity.types.forEach(value => {
              if(value.label == type.label || value.value == type.value){
                if(this.mail.cc.indexOf(elem.form.municipalTerritory.emailAddress) === -1) {
                  this.mail.cc.push(elem.form.municipalTerritory.emailAddress);
                }
              }
            })
          }
      })
    })
    //récupération des adresse mails connues
    this.mailToCC.map((elem: any) =>{
      if(this.mail.cc.indexOf(elem.value) === -1) {
        this.mail.cc.push(elem.value);
      }
    });
    //récupération des adresses mails manuelles
    this.mailToNewListCC.map((elem: any) =>{
      if(this.mail.cc.indexOf(elem.value) === -1) {
        this.mail.cc.push(elem.value);
      }
    });
  }
  updateMailListBCC(){
    this.mail.bcc = [];
    //récupération des catégories
    this.mailToCatBCC.map((type: any)=>{
      this.companies_filtered.map((elem: Company)=>{
      let test = true;
      if(elem.form && elem.form.municipalTerritory && elem.form.municipalTerritory.emailAddress &&
          elem.form.description && elem.form.description.activity && elem.form.description.activity.types) {
            elem.form.description.activity.types.forEach(value => {
              if(value.label == type.label || value.value == type.value){
                if(this.mail.bcc.indexOf(elem.form.municipalTerritory.emailAddress) === -1) {
                  this.mail.bcc.push(elem.form.municipalTerritory.emailAddress);
                }
              }
            })
          }
      })
    })
    //récupération des adresse mails connues
    this.mailToBCC.map((elem: any) =>{
      if(this.mail.bcc.indexOf(elem.value) === -1) {
        this.mail.bcc.push(elem.value);
      }
    });
    //récupération des adresses mails manuelles
    this.mailToNewListBCC.map((elem: any) =>{
      if(this.mail.bcc.indexOf(elem.value) === -1) {
        this.mail.bcc.push(elem.value);
      }
    });
  }
}
