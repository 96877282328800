import { AxiosResponse } from 'axios';
import { Store, Module} from 'vuex'
import APIService from './api.service';
import VueI18n from 'vue-i18n';
import frLocale from "assets/locales/fr.json"

interface LocalesStoreIF {
    all: any[];
    latestFetch: Date;
}

export default class LocalesService {
    static API_URL = 'https://aigle.trisline.ch/api';

    private localLocales: any;
    private store: Store<any>;
    private i18n:  VueI18n;
    private storeModule: Module<any, any> =  {
        namespaced : true,
        state: () =>({
            all : [],
            latestFetch : Date,
        }),
        mutations: {
            setLocales(state: LocalesStoreIF, locales: any[]){
                state.all = [...locales];
            }
        },
        actions: {
            refresh(context: any, i18n: VueI18n){
                return APIService.get('/locales').then((response: AxiosResponse) =>{
                    if(response.status == 200){
                        console.debug("Successfully fetched locales");
                        context.commit("setLocales", response.data)
                        for(let lang of response.data){
                            console.debug("Setting languages for "+lang.key, context.getters.getOnesStringByKey(lang.key))
                            i18n.setLocaleMessage(lang.key, lang.translation_strings);
                        }
                    }
                });
            }
        },
        getters: {
            getOneByKey: (state: LocalesStoreIF) => (key: string) => {
                return state.all.find(elem => elem.key.toLowerCase() == key.toLowerCase()) ??[];
            },
            getOnesStringByKey: (state: LocalesStoreIF) => (key: string) =>{
                let localLocale = LocalesService.get().getLocalLocale(key);
                let distantLocale = state.all.find(elem => elem.key.toLowerCase() == key.toLowerCase()) ??[];
                distantLocale.translation_strings = {...localLocale, ...distantLocale.translation_strings}
                return distantLocale
            }
        }
    }


    private constructor(store: Store<any>, i18n: VueI18n){
        this.localLocales = [];
        this.loadLocalLocales();
        this.store = store;
        this.i18n = i18n;
        store.registerModule('locales', this.storeModule);
        this.refresh();
    }

    public refresh(){
        return this.store.dispatch('locales/refresh', this.i18n)
    }

    private loadLocalLocales(){
        this.localLocales["fr"]= {...frLocale}
        //TODO other languages
    }

    public getLocalLocale(lang: string){
        return this.localLocales[lang];
    }

    static _instance: LocalesService;

    public static init(store: Store<any>, i18n: VueI18n){
        if(!this._instance)
            this._instance = new LocalesService(store, i18n);
        return this._instance;
    }

    public static get(store?: Store<any>, i18n?: VueI18n): LocalesService{
        if((!store || !i18n) && !this._instance){
            console.error("Trying to access locales service's instance without init first and passing parameters !")
            throw "Trying to access locales service's instance without init first and passing parameters !"
        } else if (this._instance){
            return this._instance;
        } else {
            return this.init(store!, i18n!)
        }
    }
}
