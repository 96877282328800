
import { Component, Vue } from "vue-property-decorator";
import RegistrationForm from "models/RegistrationForm";
import APIService from "services/api.service";
import { mapGetters } from "vuex";
import authService from "services/auth.service";


@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    }),
  
  },
  
})
export default class RegisterForm extends Vue {
  touched = false;
  taxonomies!: any;
  formValue = new RegistrationForm();
  formHasErrors = false;
  registerErrors = false;
  waiting=false;
  message = "";
  usernameAvailable: boolean|null = null;
  usernameError: boolean|null = null;
  mailError: boolean|null = null;
  mediumRegex = '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})' // eslint-disable-line
  strongRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})' // eslint-disable-line
  returnVal: any; 
  formEnd = false;
  //usernameErrorMessage = "no";

subActivitySelected(activite) {
  if (!this.formValue.activity.types.some(type => type.value === activite.value)) {
      return true; // Activité principale non sélectionnée, on considère que la condition est remplie
  }
  return this.formValue.activity.subTypes.some(
    (subType) => {
      return activite.children.map((child) => child.value).includes(subType.value);
    }
  );
}
allSubActivitiesSelected() {
  return  this.formValue.activity.types.length > 0 &&
  this.getSubTaxList("activites").every((activite) =>
    this.subActivitySelected(activite)
  );
}

    
  onTouched($event: boolean) {
    this.touched = $event;
    this.$emit("touched", this.touched);
  }
  async handleRegister() {
    this.waiting = true;
    if(this.checkFormValidity()){
      this.formHasErrors = false;
      authService.register(this.formValue).then((res: any)=> {
        if(res.status == 200)
        this.waiting = false;
           this.formEnd = true;
      }).catch( (err: any)=> {
          this.registerErrors = true;
          this.waiting = false;
      })
    } else {
      this.formHasErrors = true;
      this.waiting = false;
    }
  }

  passwordConfValidity(){
    return this.formValue.user.password == this.formValue.user.passwordConfirmation;
  }

  checkFormValidity(){
    return this.passwordConfValidity() && this.usernameAvailable;
  }

  checkUserValidity(){
    this.usernameError = false;
    this.mailError = false;
    this.usernameAvailable = false;
    this.formValue.user.username = this.formValue.user.username.trim()
    this.formValue.user.email = this.formValue.user.email.trim()
    if(this.formValue.user.username.length > 3){
      APIService.post("/users/check-availability", this.formValue.user).then((res: any)=> {
        if(res.status == 200)
          this.usernameAvailable = true;
      }).catch( (err: any)=> {
          this.usernameAvailable = false;
          if(err.response.data.error == "Username already in use"){
            this.usernameError = true;
          } else if(err.response.data.error == "Email Address already in use") {
            this.mailError = true;
          }
      })
    }
  }

  sendLogin(){
    this.$emit("subscribe", false);
  }

  zipSuggestions = [];
  cityList = {"1860": "Aigle", "1801": "Test"};

  checkCity(event){
    this.zipSuggestions = ['1860'];
  }
  autoCompleteCity(event){
    this.formValue.company.address.city = this.cityList['1860'];
  }

checkActivites(activite: any) {
    if(this.formValue.activity.types){
      return this.formValue.activity.types.filter((elem: any)=> {
        return elem.label.toLowerCase() == activite.label.toLowerCase()
      }).length == 0;
    }

    return true;
  }

  getTaxList(taxKey: string){
    return (this.taxonomies(taxKey) && this.taxonomies(taxKey).terms) ?
    this.taxonomies(taxKey).terms.map((elem: any)=>{
                              return {
                            label : elem.label,
                            value : elem.value,
                        }
    }) : []
  }

  test(val: any){
    this.onTouched(true)
  }

  getSubTaxList(taxKey: string){
    return (this.taxonomies(taxKey) && this.taxonomies(taxKey).terms) ?
    this.taxonomies(taxKey).terms.map((elem: any)=>{
        return {
          label     : elem.label,
          value     : elem.value,
          children  : elem.children.map((sub_elem: any)=>{
            return {
              label     : sub_elem.label,
              value     : sub_elem.value,
              parent    : elem.key
            }
          })
        }
    }) : []
  }
}
