
import Company from "models/Company";
//import CompanyForm from "models/CompanyForm";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters} from 'vuex';
import APIService from "services/api.service";
//import CompaniesService from "services/companies.service";
import VueSimpleAlert from "node_modules/vue-simple-alert";

import JsonCSV from 'vue-json-csv';
import Papa from 'papaparse';
import { AxiosResponse } from 'axios';

import ImportCompanies from "components/Administration/ImportCompanies.vue";
import ImportCompaniesWeb from "components/Administration/ImportCompaniesWeb.vue";
import ImportCompaniesPhone from "components/Administration/ImportCompaniesPhone.vue";
import ImportCompaniesMail from "components/Administration/ImportCompaniesMail.vue";
import AuthService from "services/auth.service";

Vue.use(VueSimpleAlert);
Vue.component('downloadCsv', JsonCSV);

@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    }),
    ...mapGetters('companies', {
      companies: 'getAll',
      companyGetter: 'getOneByKey',
    }),
    ...mapGetters('users', {
      users: 'getAll'
    }),
  },
  components: {
    ImportCompanies,
    ImportCompaniesWeb,
    ImportCompaniesPhone,
    ImportCompaniesMail
  },
})

export default class ListeAdministration extends Vue {

  selectedEntreprise = null;
  entrepriseDialog = false;
  entreprise: Company|null = null;
  entreprises_quick = [];
  filters = {};
  taxonomies!: any; // Getter from vuex for all taxonomies
  companies!: any; // Getter from vuex for all companies
  users!: any;
  user = null;
  companyGetter!: any;
  loading = 0;
  loading_status = false;
  totalComp = 1;
  superAdmin = false;

  showUser = false;

  verif(liste: any, cherche: string) {
    let j: any;

    for (j in liste) {
      if (liste[j] == cherche) {
        return false;
      }
    }
    return true;
  }

  deleteEntreprise(entreprise: any) {
    this.$confirm("Voullez-vous vraiment supprimer cette entreprise ?", "Action irréversible", "warning").then(() => {
      APIService.get("/companies/delete/"+entreprise._id);
      this.$router.push('/').then(() => this.$router.push('administration/'));
    });
  }
  deleteAllEntreprise(){
    this.$confirm("Voullez-vous vraiment supprimer toutes les entreprise en attente de valdiation ?", "Action irréversible", "warning").then(() => {
      this.$confirm("Vraiment ? Il n'y a pas de possibilité de revenir en arrière !", "Action irréversible", "warning").then(() => {
        APIService.get("/companies/destroy/pending");
        this.$router.push('/').then(() => this.$router.push('administration/'));
      });
    });
  }

  //date utility
  join(t, a, s) {
   function format(m) {
      let f = new Intl.DateTimeFormat('en', m);
      return f.format(t);
   }
   return a.map(format).join(s);
  }
  a = [{day: 'numeric'}, {month: 'numeric'}, {year: 'numeric'}];

  exportCSV() {
    const csv = Papa.unparse({
      fields: [
          "Raison sociale",
          "Forme juridique",
          "Personne de contact",
          "N° contribuable",
          "N° NOGA",
          "N° BUR",
          "Activités",
          "Sous-types d'activités",
          "Type établie",
          "Description",
          "Soumise à CCT",
          "Convention CCT",
          "Info par email",
          "Enseigne",
          "Début de l'activité",
          "Téléphone",
          "Email",
          "Fax",
          "Site internet",
          "Adresse",
          "P.A.",
          "Surf. des locaux",
          "Capital social",
          "Siège Sign",
          "Nbre d'employé(s) dans l'administration",
          "Nbre d'employé(s) de production",
          "Travailleurs hommes suisses",
          "Travailleurs hommes étrangers",
          "Travailleurs hommes temps complet",
          "Travailleurs hommes partiels >50%",
          "Travailleurs hommes partiels <50%",
          "Travailleurs femmes suisses",
          "Travailleurs femmes étrangers",
          "Travailleurs femmes temps complet",
          "Travailleurs femmes partiels >50%",
          "Travailleurs femmes partiels <50%",
          "Travailleurs permis B",
          "Travailleurs permis C",
          "Travailleurs permis L",
          "Travailleurs autres permis",
          "Date de la fin d'activité",
          "Cause de la fin d'activité",
          "Précisions sur la fin d'activité",
          "Contact d'urgence : Fonctions",
          "Contact d'urgence : Titre",
          "Contact d'urgence : Nom",
          "Contact d'urgence : Prénom",
          "Contact d'urgence : Adresse",
          "Contact d'urgence : Mobile",
          "Contact d'urgence : Téléphone",
          "Contact d'urgence : Email",
          "Contact d'urgence : Fax",
          "Contact d'urgence : Autre(s)",
          "RC : enregistré",
          "RC : numéro",
          "RC : date",
          "N° AVS",
          "N° alloc. familiale",
          "ID",
          "Status de validation",
          "Date de creation",
          "Date de mise a jour",
          "Notes"
        ],
      data: this.filterResult(this.companies).map((elem: any)=>{
        let activityStartDate
        let endOfActivityDate
        let rcDate
        let createdDate
        let updateDate
        try{
          activityStartDate = this.join(new Date(elem.form.municipalTerritory.activityStartDate), this.a, '/')
        } catch {
          activityStartDate = ""
        }
        try{
          endOfActivityDate = this.join(new Date(elem.form.endOfActivity.date), this.a, '/')
        } catch {
          endOfActivityDate = ""
        }
        try{
          rcDate = this.join(new Date(elem.form.rc_registration.date), this.a, '/')
        } catch {
          rcDate = ""
        }
        try{
          createdDate = this.join(new Date(elem.created_at), this.a, '/')
        } catch {
          createdDate = ""
        }
        try{
          updateDate = this.join(new Date(elem.updated_at), this.a, '/')
        } catch {
          updateDate = ""
        }
        return [
        elem.form.description.socialReason,
        elem.form.description.legalForm.value,
        elem.form.contact_full,
        elem.form.description.taxpayerNo,
        elem.form.description.NOGANo,
        elem.form.description.BURNo,
        elem.form.description.activity.listAllTypes(),
        elem.form.description.activity.listAllSubTypes(),
        elem.form.description.activity.listAllComTypes(),
        elem.form.description.activity.description,
        elem.form.description.cct.state,
        elem.form.description.cct.name,
        elem.form.description.newsletterSub,
        elem.form.description.enseigne,
        activityStartDate,
        elem.form.municipalTerritory.phoneNumber,
        elem.form.municipalTerritory.emailAddress,
        elem.form.municipalTerritory.faxNumber,
        elem.form.municipalTerritory.websiteURL,
        elem.form.municipalTerritory.address,
        elem.form.municipalTerritory.forAddress,
        elem.form.municipalTerritory.premisesSurface,
        elem.form.municipalTerritory.shareCapital,
        elem.form.siege.sign,
        elem.form.workforce.administration,
        elem.form.workforce.production,
        elem.form.workforce.men.swiss,
        elem.form.workforce.men.strangers,
        elem.form.workforce.men.fulltime,
        elem.form.workforce.men.parttime,
        elem.form.workforce.men.parttime2,
        elem.form.workforce.women.swiss,
        elem.form.workforce.women.strangers,
        elem.form.workforce.women.fulltime,
        elem.form.workforce.women.parttime,
        elem.form.workforce.women.parttime2,
        elem.form.workforce.permits.B,
        elem.form.workforce.permits.C,
        elem.form.workforce.permits.L,
        elem.form.workforce.permits.other,
        endOfActivityDate,
        elem.form.endOfActivity.cause.value,
        elem.form.endOfActivity.description,
        elem.form.contacts[0].listAllFunc(),
        elem.form.contacts[0].title,
        elem.form.contacts[0].last_name,
        elem.form.contacts[0].first_name,
        elem.form.contacts[0].address.street + " " + elem.form.contacts[0].address.zip + " " + elem.form.contacts[0].address.city ,
        elem.form.contacts[0].mobile,
        elem.form.contacts[0].telephone,
        elem.form.contacts[0].email,
        elem.form.contacts[0].fax,
        elem.form.contacts[0].other,
        elem.form.rc_registration.etat,
        elem.form.rc_registration.numero,
        rcDate,
        elem.form.AVSno,
        elem.form.familyAllowanceNo,
        elem._id,
        elem.form.validation_status,
        createdDate,
        updateDate,
        elem.adminNotes
      ]})},
      {
        delimiter: ";"
      });
    this.forceFileDownload(csv);
  }
  forceFileDownload(response){
    console.log(response)
      const url = window.URL.createObjectURL(new Blob(["\ufeff", response],{type:"text/plain;charset=UTF-8"}))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'entreprise.csv') //or any other extension
      document.body.appendChild(link)
      link.click()
  }

  editEntreprise(entreprise: any) {
    this.$router.push('/administration/entreprises/'+entreprise._id);
  }

  editEntrepriseInLine(event: any){
    this.editEntreprise({...event.data})
  }

  validateEntreprise(entreprise: any) {
    APIService.get('/companies/'+entreprise._id).then((response: AxiosResponse) =>{
                    if(response.status == 200){
                        let company = Company.getCompanyFromList(response.data);
                        APIService.post("/companies/" + entreprise._id + "/forms/adminValidate", company.form).then(() => {
                          APIService.get('/companies/'+entreprise._id + '/updateCache').then(() => {
                            this.$alert("Vos modifications ont bien été sauvegardées !", "Entreprise Validée", "success")
                          })
                        });
                    } else {
                        this.refreshList()
                    }
                });
  }
  refreshList() {
    /*let i = 0*/
    /*let active = 0
    let inactive = 0
    this.companies.forEach(element => {
      this.userInfo(element)
      if (this.user){
        if (this.user.email.indexOf("@trisinformatique.com") != -1){
          if (element.form.endOfActivity.cause.label){
            inactive += 1
          } else {
            console.log(element.form.description.socialReason)
            active += 1
          }
        }
      } else {
        console.log("NO USERS ! ")
        console.log(element)
      }
    })
    console.log(inactive)
    console.log(active)*/
    /*
        this.validateEntreprise(element)
        if (element.form.municipalTerritory.websiteURL.indexOf("undefined") != -1){
          console.log(element.form.municipalTerritory.websiteURL)
          element.form.municipalTerritory.websiteURL = element.form.municipalTerritory.websiteURL.replace("undefined", "")
          APIService.post("/companies/"+element._id+"/forms/update", element.form);
        }
      }
    });
   */
    this.$router.push('/').then(() => this.$router.push('administration/'));
    //PosteAPIService.getAuthorization().then((response) => {console.log(response.data)})
  }

  registreComm = [];
  urgenceFonction = [];
  directeurFonction = [];
  chefFonction = [];

  //FILTER LIST
  nameToFiltre = "";
  newsletterFiltre = [];
  catToFiltre = [];
  statToFiltre = [{label: "Actives", value: 0}];
  workflowFiltre = [];

  //récupère liste de catégories
  getTaxList(taxKey: string){
    return (this.taxonomies(taxKey) && this.taxonomies(taxKey).terms) ?
    this.taxonomies(taxKey).terms.map((elem: any)=>{
                              return {
                            label : elem.label,
                            value : elem.value,
                        }
    }) : []
  }
  activNoActive = [{label: "Actives", value: 0},{label: "Inactives", value: 1}]
  workflowList = [{label: "Validée", value: "validated"},{label: "En attente", value: "pending_validation"},{label: "Brouillon", value: "created"}]
  newsletterOptions = [
      { label: 'Abonnés à la newsletter', value: true },
      { label: 'Non abonnés à la newsletter', value: false }
    ];


  filterResult(companies){
    
    return companies.filter(comp => {
        comp.form.description.socialReason = comp.form.description.socialReason.toUpperCase()
        let ret = false;
        let ret1 = false;
        let ret2 = false;
        let ret3 = false;
        let newsletterFilterValues = this.newsletterFiltre.map(filter => filter.value);
        let isNewsletterFilterActive = newsletterFilterValues.length > 0;
      let ret4 = !isNewsletterFilterActive || newsletterFilterValues.includes(comp.form.description.newsletterSub);
        if(this.catToFiltre && this.catToFiltre.length > 0){
          comp.form.description.activity.types.forEach( cat => {
            this.catToFiltre.forEach(filterCat => {
              if(filterCat.label == cat.label){
                ret1 = true;
              }
            })
          });
        } else {
          ret1 = true;
        }
        if(this.statToFiltre && this.statToFiltre.length == 1){
          let today = new Date();
          today.setHours(0,0,0,0);
          if(this.statToFiltre[0].value == 1){
            // filter for inactives
            ret = comp.form.endOfActivity.date && comp.form.endOfActivity.date && (new Date(comp.form.endOfActivity.date) < today) && comp.form.endOfActivity.cause.label;
          } else if(this.statToFiltre[0].value == 0){
            // filter for actives
            ret = !comp.form.endOfActivity.date || (new Date(comp.form.endOfActivity.date) >= today) || !comp.form.endOfActivity.cause.label;
          }
          ret = ret && ret1;
        } else {
          ret = ret1;
        }
        if(this.workflowFiltre && this.workflowFiltre.length > 0){
          this.workflowFiltre.forEach(filterW => {
            if(filterW.value == comp.form.validation_status){
              ret2 = true;
            }
          })
          ret2 = ret2 && ret
        } else {
          ret2 = ret;
        }
        if(this.nameToFiltre){
          if(comp.form.description.socialReason.includes(this.nameToFiltre.toUpperCase())){
            ret3 = true;
          } else if(comp.form.contact_full.toLowerCase().includes(this.nameToFiltre.toLocaleLowerCase())){
            ret3 = true;
          } else if(comp.form.municipalTerritory.address.toLowerCase().includes(this.nameToFiltre.toLocaleLowerCase())){
            ret3 = true;
          } else if(comp.form.municipalTerritory.emailAddress.toLowerCase().includes(this.nameToFiltre.toLocaleLowerCase())){
            ret3 = true;
          } else if(comp.form.municipalTerritory.phoneNumber.toLowerCase().includes(this.nameToFiltre.toLocaleLowerCase())){
            ret3 = true;
          } 
          ret3 = ret3 && ret2
        } else {
          ret3 = ret2;
        }
        return ret3 && ret4;
      })
  }

  /*fetchData(){
    return APIService.get('companiesCount').then((response: AxiosResponse) =>{
      this.totalComp = response.data
      this.fetchDataStep(0)
    });
  }
  fetchDataStep(i: any){
    APIService.get('/companiesshortadm/' + i).then((response: AxiosResponse) =>{
          if (response.data == "DONE" || this.companies.length != 0){
            console.debug("Successfully fetched companies ");
          } else {
            this.entreprises_quick = this.entreprises_quick.concat(Company.getCompaniesFromList(response.data));
            this.fetchDataStep(i+1)
            this.loading += 20/this.totalComp*100
          }
        });
  }*/

  rebuildCache(){
    this.loading_status = true;
    return APIService.get('companiesCount').then((response: AxiosResponse) =>{
      this.totalComp = response.data
      this.rebuildCacheStep(0)
    })
  }

  rebuildCacheStep(i: any){
    APIService.get('/rebuildcache/' + i).then((response: AxiosResponse) =>{
          if (response.data == "Done"){
            this.$alert("La cache a bien été reconstruit !", "Reconstruction terminée", "success")
            this.loading_status = false;
            this.loading = 0;
          } else {
            this.rebuildCacheStep(i+1)
            this.loading += 100/this.totalComp*100
          }
        });
  }

  userInfo(entreprise: any){
    this.user = null;
    this.selectedEntreprise = entreprise;
    this.users.forEach(element => {
      if(element.company_id == entreprise._id){
        this.user = element
      }
    });
    this.showUser = true;
  }

  removeDoubleAuth() {
    if(this.user){
      APIService.get("/users/removedoubleauth/" + this.user._id).then((response) => {
        if(response.status == 200){
          this.$alert("La double authentification a bien été enlevée !", "Utilisateur mis à jour", "success")
        }
      })
    } else{
      console.log("Erreur, pas d'utilisateur défini...")
    }
  }
  updateMailUser() {
    if(this.user){
      APIService.post("/users/"+ this.user._id + "/update", this.user ).then((response) => {
        if(response.status == 200){
          this.$alert("La double authentification a bien été enlevée !", "Utilisateur mis à jour", "success")
        }
      })
    }else{
      console.log("Erreur, pas d'utilisateur défini...")
    }
  }

  mounted(){
    //this.fetchData();
    if(localStorage.getItem('nameToFiltre') != "null"){
      this.nameToFiltre = JSON.parse(localStorage.getItem('nameToFiltre'))
    }
    if(localStorage.getItem('newsletterFiltre') != "null" && localStorage.getItem('newsletterFiltre') != "[]"){
      this.newsletterFiltre = JSON.parse(localStorage.getItem('newsletterFiltre'))
    }
    if(localStorage.getItem('catToFiltre') != "null" && localStorage.getItem('catToFiltre') != "[]"){
      this.catToFiltre = JSON.parse(localStorage.getItem('catToFiltre'))
    }
    if(localStorage.getItem('statToFiltre') != "null" && localStorage.getItem('statToFiltre') != ""){
      this.statToFiltre = JSON.parse(localStorage.getItem('statToFiltre'))
    }
    if(localStorage.getItem('workflowFiltre') != "null" && localStorage.getItem('workflowFiltre') != "[]"){
      this.workflowFiltre = JSON.parse(localStorage.getItem('workflowFiltre'))
    }
    this.superAdmin = AuthService.userCan('super-admin')
  }

  beforeDestroy(){
    localStorage.setItem("nameToFiltre", JSON.stringify(this.nameToFiltre));
    localStorage.setItem("newsletterFiltre", JSON.stringify(this.newsletterFiltre));
    localStorage.setItem("catToFiltre", JSON.stringify(this.catToFiltre));
    localStorage.setItem("statToFiltre", JSON.stringify(this.statToFiltre));
    localStorage.setItem("workflowFiltre", JSON.stringify(this.workflowFiltre));
  }
}


