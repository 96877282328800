
import { Component, Vue } from "vue-property-decorator";
import APIService from "services/api.service";
import Papa from 'papaparse';
import RegistrationForm from "models/RegistrationForm";
import authService from "services/auth.service";
import Taxonomy from 'models/Taxonomy';
import { mapGetters } from "vuex";
import { TaxonomyTerm } from "models/Taxonomy";
import { CompanyWebPage } from "models/Company";
import { CompanyContactInformation } from "models/CompanyForm";

export function formatPhone(phone){
  let ret = "";
  phone = phone.trim().replaceAll(' ', '').replaceAll('/','').replaceAll('.','').replaceAll('\'', '').replaceAll('(', '').replaceAll(')','').replaceAll('-', '').replace(/\D/g,'');
  if (phone[0] == '+'){
    if(phone[1] == 0 && phone.length == 13){
      phone = phone[0] + phone.slice(2,13);
    } else if(phone[3] == 0 && phone.length == 13){
      phone = phone.slice(0,3) + phone.slice(4,13);
    }
    if(phone.length == 12){
      ret = phone.slice(0, 3) + " " + phone.slice(3, 5) + " " + phone.slice(5, 8) + " " + phone.slice(8, 10) + " " + phone.slice(10, 12);
    }
  } else {
    let s = phone.slice(0, 2);
    if (s == "00"){
      if(phone.length == 13){
        ret = "+" + phone.slice(2, 4) + " " + phone.slice(4, 6) + " " + phone.slice(6, 9) + " " + phone.slice(9, 11) + " " + phone.slice(11, 13);
      }
    } else if ( s == "02" || s == "08" || s == "07" || s == "05" || s == "03" || s=="04"){
      if(phone.length == 10) {
        ret = "+41 " + phone.slice(1, 3) + " " + phone.slice(3, 6) + " " + phone.slice(6, 8) + " " + phone.slice(8, 10);
      }
    } else if ( s == "41") {
      if(phone.length == 11){
        ret = "+" + phone.slice(0, 2) + " " + phone.slice(2, 4) + " " + phone.slice(4, 7) + " " + phone.slice(7, 9) + " " + phone.slice(9, 11);
      }
    } else {
      if(phone.length == 9){
        ret = "+41 " + phone.slice(0, 2) + " " + phone.slice(2, 5) + " " + phone.slice(5, 7) + " " + phone.slice(7, 9);
      }
    }
  }
  if(ret == ""){
    console.debug("NO PHONE FOUND FOR : " + phone);
  }
  return ret;
}

let globalData = new Vue({
  data: { $importTotal: 0,
          $importStep: 0,
          $importProg: 0,
          $userbase: [],
          $companies: [],}
});
Vue.mixin({
  computed: {
    $importTotal: {
      get: function () { return globalData.$data.$importTotal },
      set: function (newData) { globalData.$data.$importTotal = newData; }
    },
    $importStep: {
      get: function () { return globalData.$data.$importStep },
      set: function (newData) { globalData.$data.$importStep = newData; }
    },
    $importProg: {
      get: function () { return globalData.$data.$importProg },
      set: function (newData) { globalData.$data.$importProg = newData; }
    },
    $companies: {
      get: function () { return globalData.$data.$companies },
      set: function (newData) { globalData.$data.$companies = newData; }
    }
  }
})
@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    }),
    ...mapGetters('companies', {
      companies: 'getAll',
      companyGetter: 'getOneByKey',
    }),
  }
})
export default class ImportCompaniesPhone extends Vue {

  importDialog = false;
  loadingDialog = false;
  companies!: any; // Getter from vuex for all companies
  csvSource = null;

  uploadDialog(){
    this.csvSource = null;
    this.importDialog = true;
  }
  requestUploadFile(){
      this.csvSource = this.$el.querySelector('#uploadmyfile')
  }

  refreshList() {
    this.$router.push('/').then(() => this.$router.push('administration/'));
  }

  getImportProgress() {
    if (globalData.$data.$importProg == globalData.$data.$importTotal && globalData.$data.$importTotal > 0){
      this.loadingDialog = false;
      this.refreshList();
      globalData.$data.$importProg = 0;
      globalData.$data.$importTotal = 0;
      globalData.$data.$importStep = 0;
    }
    return "Terminés : " + globalData.$data.$importProg + "/" + globalData.$data.$importTotal;
  }
  getImportPorcent() {
    let ret = "0";
    if (globalData.$data.$importTotal > 0){
      ret = parseFloat(""+(globalData.$data.$importStep / (5*globalData.$data.$importTotal))*100).toFixed(2) + "%";
    }
    return ret;
  }

  async importCSV(bigFile) {
    this.importDialog = false;
    this.loadingDialog = true;
    globalData.$data.$importTotal = 0;
    globalData.$data.$importProg = 0;
    globalData.$data.$importStep = 0;
    APIService.get('/companies').then((res) => {
      globalData.$data.$companies = this.companies;
      console.log(globalData.$data.$companies)
      Papa.parse(this.csvSource.files[0], {
        worker: true,
        //encoding: 'latin1',
        delimiter: ";;",
        step: async function(row) {
          globalData.$data.$importTotal += 1;
          try {
              let ok = false;
              if (row.data[0]){
                let sr = row.data[0].trim().replace(/\w\S*/g, function(txt) {return txt.toLowerCase();})
                let companies = globalData.$data.$companies
                companies.forEach(comp => {
                  if (comp.form.description.socialReason.toLowerCase() == sr || comp.form.description.enseigne.toLowerCase() == sr ){
                    ok = true
                    let phone = formatPhone(row.data[3])
                    if (phone){
                      comp.form.municipalTerritory.phoneNumber = phone;
                      APIService.post("/companies/"+comp._id+"/forms/update", comp.form).then((response) =>{
                        APIService.post("/companies/" + comp._id + "/forms/adminValidate", comp.form).then((respons) => {
                          globalData.$data.$importProg = globalData.$data.$importProg + 1;
                        })
                      })
                    }
                  }
                });
                if(!ok){
                  console.log("NOT OK : " + row.data[0])
                  globalData.$data.$importProg = globalData.$data.$importProg + 1;
                }
              }
          } catch {
            console.log("ERROR")
            globalData.$data.$importProg = globalData.$data.$importProg + 1;
          }

        },
        complete: function() {
          console.debug("Importation task created");
        }
      });
    })

  }

}

