








































import {Component, Vue } from "vue-property-decorator";

@Component
export default class FormulaireInscription extends Vue {
  inscription = {};

  EnvoisInscription() {
    /*this.axios
                    .post('http://localhost:8000/register', this.inscription)
                    .then(response => (
                        console.log(response.data)
                    ))
                    .catch(error => console.log(error))*/
  }

}
