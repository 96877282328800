/* eslint-disable */
import { ContactInformation } from "./common";
import { TaxonomyTerm } from "./Taxonomy";
import sanitizeHTML from "sanitize-html";
import User from "./User";


export default class RegistrationForm {

    user    : RegistrationUserForm;
    company : RegistrationCompanyForm;
    contact : RegistrationContactForm;
    activity : RegistrationCompanyActivityForm;


    public constructor(object?: any){
        object                  = object || {}

        this.user     = new RegistrationUserForm(object.user);
        this.company  = new RegistrationCompanyForm(object.company);
        this.contact  = new RegistrationContactForm(object.contact);
        this.activity = new RegistrationCompanyActivityForm(object.activity);
    }
}

export class RegistrationUserForm extends User{

    passwordConfirmation    : string;


    public constructor(object?: any){
        super(object);
        object                        = object || {}

        this.passwordConfirmation     = object.passwordConfirmation    || "";
    }
}

export class RegistrationContactForm extends ContactInformation{

    first_name               : string;
    last_name                : string;
    director                : boolean;
    chief                   : boolean;

    public constructor(object?: any){
        object                        = object || {}
        super(object);

        this.first_name                = object.first_name    || "";
        this.last_name                 = object.last_name     || "";
        this.director                 = object.director     || false;
        this.chief                    = object.chief        || false;
    }
}

export class RegistrationCompanyForm extends ContactInformation{

    socialReason : string;
    siege        : boolean;

    public constructor(object?: any){
        object                        = object || {}
        super(object);

        this.socialReason   = object.socialReason   || "";
        this.siege          = object.siege          || false;
    }
}

export class RegistrationCompanyActivityForm {
    description  : string
    types        : TaxonomyTerm[]
    subTypes     : TaxonomyTerm[]
    company_type : string[]

    public constructor(object?: any){
        object                  = object || {}
        this.description        = object.description || "";
        this.types              = TaxonomyTerm.getTermsFromList(object.types);
        this.subTypes           = TaxonomyTerm.getTermsFromList(object.subTypes);
        this.company_type       = [...(object.company_type || [])]
    }

    public listAllTypes(){
        let allTypes = "";
        this.types.forEach(element => {
            allTypes += element.value + " ";
        });
        return allTypes;
    }
    public listAllSubTypes(){
        let allSubTypes = "";
        this.subTypes.forEach(element => {
            allSubTypes += element.value + " ";
        });
        return allSubTypes;
    }
    public listAllComTypes(){
        let allComTypes = "";
        this.company_type.forEach(element => {
            allComTypes += element + " ";
        });
        return allComTypes;
    }

}