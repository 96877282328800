
/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Component
 * Purpose  : Vue component that manages company contact infos and
 *            is used to be validated by the Commune
 *************************************************************/

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { CompanyAssociate } from "models/CompanyForm";
import { mapGetters } from "vuex";
import { convert_to_snake_case } from "@/utils"

@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    })
  }
})
export default class CompanyAssociateInfoFormComponent extends Vue {

  @Prop({ type: CompanyAssociate }) value!: CompanyAssociate

  get associateInfo() {
    return this.value;
  }
  set associateInfo(desc: CompanyAssociate) {
    if(!desc)
      desc = new CompanyAssociate();
    this.$emit("input", desc);
  }


  @Watch("value", { immediate: true, deep: true })
  onInstanceChange(desc: any) {
      if(!desc)
        desc = new CompanyAssociate();
      this.$emit("input", desc);
  }

  touched = false;

  @Prop()
  title!: string

  @Prop()
  index!: number

  /* ------------------- */

  onTouched($event: boolean) {
    this.touched = $event;
    this.$emit("touched", this.associateInfo);
  }

  deleteself(){
    this.$emit("delete", this.index);
  }

  sendCancel() {
    this.$emit("cancel", true);
    this.touched = false;
  }

  sendSave() {
    this.$emit("save", this.associateInfo);
    this.touched = false;
  }

  getCSS_Prefix(){
    return convert_to_snake_case(this.title);
  }
}
