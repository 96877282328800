
import Vue from "vue";
import AuthService from "services/auth.service";
import Component from "vue-class-component";
import MenuItem from "models/MenuItem"
import { Prop, Watch } from "vue-property-decorator";
import { Route } from "vue-router";

@Component
export default class SidebarMenuItem extends Vue {
  _item = new MenuItem();
  hover       = false;
  childHover  = false;

  @Prop()
  item!: MenuItem;

  @Prop()
  width!: number;

  @Prop()
  isChild!: boolean;

  currentPath = "";

  @Watch('$route', { immediate: true, deep: true })
  onRouteChanged(to: Route, from: Route) {
    this.currentPath=to.path
  }

  checkAuthorisation(item: MenuItem){
    if(item){
      if(item.role && AuthService.userRoleIs(item.role))
        return true;
      else if(item.userCan && AuthService.userCan(item.userCan))
        return true
    }
    return false;
  }

  getChildStyle(){
    return {
        //opacity : (this.hover || this.childHover ? 1 : 0),
        "pointer-events" : (this.hover || this.childHover ? 'all' : 'none'),
        transform        : (this.hover || this.childHover ? 'translateX(100%)' : 'none')
      }
  }

  getButtonClasses(){
    let result = "trs-menu-button ";
    result += (this.item.items && this.item.items.length ? 'has-children ' : '' )
    result += ( (this.item.to || this.item.command || this.item.url) && !this.item.disabled ? 'clickable ' : '' )

    return result
  }

  handleCLick(){
    if(this.item.to){
      this.$router.push(this.item.to).catch((err)=>{
        return;
      });
    } else if (this.item.command){
      this.item.command();
    } else if (this.item.url){
      window.open(this.item.url, '_blank');
    }
  }

  checkCurrentPath(item: MenuItem){
    return MenuItem.hasChildrenWithPath(this.currentPath, item)
  }

  onHover(){
    this.hover = true
  }

  onLeave(){
    setTimeout(()=> {
      if(this.hover)
        this.hover = false
    }, 100)
  }

  onChildHover(){
    this.childHover = true
  }

  onChildLeave(){
    this.childHover = false
  }
}
