
/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Component
 * Purpose  : Vue component that manages company end of activity infos and
 *            is used to be validated by the Commune
 *************************************************************/

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { CompanyEndofActivity } from "models/CompanyForm";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    })
  }
})
export default class CompanyEndActivityFormComponent extends Vue {
  touched = false;
  taxonomies!: any; // Getter from vuex for all taxonomies
  /* ----- v-model ----- */
  @VModel({ type: CompanyEndofActivity }) endOfActivity!: CompanyEndofActivity
  /* ------------------- */

  created(){
    if (!this.endOfActivity.cause.label && !this.endOfActivity.cause.value){
      this.endOfActivity.date = null;
    }
  }

  onTouched($event: boolean) {
    this.touched = $event;
    this.$emit("touched", this.endOfActivity);
  }

  sendCancel() {
    this.$emit("cancel", true);
    this.touched = false;
  }

  sendSave() {
    this.$emit("save", this.endOfActivity);
    this.touched = false;
  }

  getTaxList(taxKey: string){
    return (this.taxonomies(taxKey) && this.taxonomies(taxKey).terms) ?
    this.taxonomies(taxKey).terms.map((elem: any)=>{
                              return {
                            label : elem.label,
                            value : elem.value,
                        }
    }) : []
  }
}
