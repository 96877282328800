import { AxiosResponse } from 'axios';
import { Store, Module} from 'vuex'
import APIService from './api.service';
import Taxonomy from 'models/Taxonomy';

interface TaxonomiesStoreIF {
    all: Taxonomy[];
    latestFetch: Date;
}

export default class TaxonomiesService {
    static API_URL = 'https://aigle.trisline.ch/api';

    private store: Store<any>;
    private storeModule: Module<any, any> =  {
        namespaced : true,
        state: () =>({
            all : [],
            latestFetch : Date,
        }),
        mutations: {
            setTaxonomies(state: TaxonomiesStoreIF, taxonomies: Taxonomy[]){
                state.all = [...taxonomies];
            }
        },
        actions: {
            refresh(context: any){
                return APIService.get('/taxonomies').then((response: AxiosResponse) =>{
                    if(response.status == 200){
                        console.debug("Successfully fetched taxonomies");
                        context.commit("setTaxonomies", Taxonomy.getTaxonomiesFromList(response.data))
                    }
                });
            }
        },
        getters: {
            getOneByKey: (state: TaxonomiesStoreIF) => (key: string) => {
                return state.all.find(elem => elem.key.toLowerCase() == key.toLowerCase()) ??[];
            },
        }
    }


    private constructor(store: Store<any>){
        store.registerModule('taxonomies', this.storeModule);
        this.store = store;
        this.refresh();
    }

    public refresh(){
        return this.store.dispatch('taxonomies/refresh')
    }

    static _instance: TaxonomiesService;

    public static init(store: Store<any>){
        if(!this._instance)
            this._instance = new TaxonomiesService(store);
        return this._instance;
    }

    public static get(store?: Store<any>): TaxonomiesService{
        if(!store && !this._instance){
            console.error("Trying to access taxonomies service's instance without init first and passing store !")
            throw "Trying to access taxonomies service's instance without init first and passing store !"
        } else if (this._instance){
            return this._instance;
        } else {
            return this.init(store!)
        }
    }
}
