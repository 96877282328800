
import { Component, Vue } from "vue-property-decorator";
import Mail from 'models/Mail';
import { mapGetters} from 'vuex';
import AuthService from "services/auth.service";
import APIService from "services/api.service";

@Component({
  computed: {
    ...mapGetters('mails', {
      mails: 'getAll',
    }),
  }
})
export default class EmailList extends Vue {
  nameToFiltre = "";
  mails!: any;
  mail = new Mail();
  showDialog = false;
  filters = {};
  superAdmin = false;


  showMail(mail: any){
    this.mail = new Mail({...mail.data});
    this.showDialog = true;
  }
  refreshList() {
    this.$router.push('/').then(() => this.$router.push('/administration/emails/envoyes'));
  }

      //date utility
  join(t, a, s) {
   function format(m) {
      let f = new Intl.DateTimeFormat('en', m);
      return f.format(t);
   }
   return a.map(format).join(s);
  }
  a = [{day: 'numeric'}, {month: 'numeric'}, {year: 'numeric'}];

  createdDate(elem: any){
    return this.join(new Date(elem.sent_at), this.a, '/');
  }
  correctDisplay(elem: any){
    let arr = "";
    for(let e in elem){
      arr += elem[e] + ", "
    }
    arr = arr.slice(0, arr.length-2)
    return arr
  }

  mounted() {
    this.superAdmin = AuthService.userCan('super-admin')
  }

  deleteAllMail(){
    this.$confirm("Voullez-vous vraiment supprimer tous les mails ?", "Action irréversible", "warning").then(() => {
      this.$confirm("Vraiment ? Il n'y a pas de possibilité de revenir en arrière !", "Action irréversible", "warning").then(() => {
        APIService.get("/mails/destroy");
      });
    });
  }

  filterResult(mails){
    return mails.filter(mail => {
      let isAccepted = false;
      if (this.nameToFiltre) {
        let nameToFiltre = this.nameToFiltre.toLowerCase();
        if (this.correctDisplay(mail.to).toLowerCase().includes(nameToFiltre)) {
          isAccepted = true;
        } else if (mail.from.toLowerCase().includes(nameToFiltre)) {
          isAccepted = true;
        } else if (this.correctDisplay(mail.cc).toLowerCase().includes(nameToFiltre)) {
          isAccepted = true;
        } 
        else if (this.correctDisplay(mail.bcc).toLowerCase().includes(nameToFiltre)) {
          isAccepted = true;
        } 
        else if (this.createdDate(mail).toLowerCase().includes(nameToFiltre)) {
          isAccepted = true;
        } 
        else if (mail.object.toLowerCase().includes(nameToFiltre)) {
          isAccepted = true;
        } 
      } else {
        isAccepted = true;
      }
      return isAccepted;
    })
  }
}
