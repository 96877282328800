import { render, staticRenderFns } from "./ImportCompaniesWeb.vue?vue&type=template&id=c35c0568&"
import script from "./ImportCompaniesWeb.vue?vue&type=script&lang=ts&"
export * from "./ImportCompaniesWeb.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports