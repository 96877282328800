
import CompaniesService from 'services/companies.service';
import LocalesService from 'services/locales.service';
import NavigationService from 'services/nav.service';
import TaxonomiesService from 'services/taxonomies.service';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TrsBasicView extends Vue {
  title = ""

  created(){
    NavigationService.get().setCurrentPageTitle(this.title);
    TaxonomiesService.get().refresh();
    LocalesService.get().refresh();
    CompaniesService.get().refresh();
  }

  setTitle(title: string){
    this.title = title;
    NavigationService.get().setCurrentPageTitle(this.title);
  }
}
