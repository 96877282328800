
import { Component, Vue } from "vue-property-decorator";
import {Entreprise} from "models/Entreprise"
import { mapGetters } from "vuex";
import Company from "models/Company";
import APIService from 'services/api.service';
import { AxiosResponse } from 'axios';
import CompanyAssociateInfoFormComponent from "./CompanyFormForm/form-parts/CompanyAssociateInfoForm.vue";


@Component({
  computed: {
    ...mapGetters('companies', {
      companies: 'getAll',
    })
  }
})
export default class CompanyPublicList extends Vue {
  nameToFiltre = "";
  companies!: any;
  entrepriseDialog = false;
  entreprise: Company|null = null;
  //entreprises_quick = [];
  filters = {};
  allowClosing = false;

  cols = [
    { field: 'raisonSoc', header: 'Enseigne' },
    { field: 'activite', header: 'Domaine d\'activité' },
    { field: 'adresse', header: 'Adresse' },
    { field: 'tel', header: 'Téléphone' }
  ];

  verif(liste: any) {
    let j: any;

    for (j in liste) {
        return j;
    }

  }

  onRowSelect(event: any) {
    this.allowClosing = false
    this.entreprise = new Company({...event.data});
    this.entrepriseDialog = true;
    setTimeout(() => { this.allowClosing = true }, 1000);
  }

  refreshList() {
    this.$router.push('/inscription').then(() => this.$router.push('/'));
  }

  /*fetchData(){
    return APIService.get('/').then((response: AxiosResponse) =>{
      this.fetchDataStep(0)
    });
  }
  fetchDataStep(i: any){
    APIService.get('/companiesshort/' + i).then((response: AxiosResponse) =>{
          if (response.data == "DONE" || this.companies.length != 0){
            console.debug("Successfully fetched companies ");
          } else {
            this.entreprises_quick = this.entreprises_quick.concat(Company.getCompaniesFromList(response.data.public_cache));
            this.fetchDataStep(i+1)
          }
        });
  }

  mounted(){
    this.companies.forEach(element => {
      console.log(element.form.description.showOnWeb)
    });
  }*/

  removeCity(data: any){
    if(typeof(data.form.municipalTerritory.address) == typeof(" ")){
      return data.form.municipalTerritory.address.replace(", 1860 Aigle", "")
    }else{
      return data.form.municipalTerritory.address
    }
  }

  closeDial(){
    if(this.allowClosing){
      this.entrepriseDialog = false;
    }
  }

  filterResult(companies) {
  return companies.filter(comp => {
    // Convertir la raison sociale en majuscules
    comp.form.description.socialReason = comp.form.description.socialReason.toUpperCase();

    // Générer le résumé de l'activité
    let first = true;
    comp.form.description.activity.types.forEach(type => {
      let labelTranslated = this.$t(type.label); // Utilisation de this.$t sans le deuxième argument
      if (typeof labelTranslated === 'string') { // Vérification du type de labelTranslated
        let endIdx = labelTranslated.indexOf(' |');
        let resumePart = labelTranslated.substring(0, endIdx !== -1 ? endIdx : labelTranslated.length).trim();
        if (first) {
          comp.form.description.activity.resume = resumePart;
          first = false;
        } else {
          comp.form.description.activity.resume += ', ' + resumePart;
        }
      }
    });

    // Vérifier le statut de validation, la date de fin d'activité et l'affichage web
    let isValidatedAndActive = comp.form.validation_status === 'validated' &&
      (!comp.form.endOfActivity.date || 
        (comp.form.endOfActivity.date && new Date(comp.form.endOfActivity.date) >= new Date() && comp.form.endOfActivity.cause.label)) &&
      comp.form.description.showOnWeb;

    // Appliquer le filtrage additionnel par nom si spécifié
    if (isValidatedAndActive && this.nameToFiltre) {
      let nameToFiltre = this.nameToFiltre.toLowerCase();
      return this.checkAndCompare(comp.form.description.socialReason, nameToFiltre) ||
             this.checkAndCompare(comp.form.description.activity.resume, nameToFiltre) ||
             this.checkAndCompare(this.removeCity(comp), nameToFiltre) ||
             this.checkAndCompare(comp.form.municipalTerritory.phoneNumber, nameToFiltre);
    }

    return isValidatedAndActive;
  });
}

  checkAndCompare(value: string | undefined, filter: string): boolean {
    return value ? value.toLowerCase().includes(filter) : false;
  }
}

