
/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Component
 * Purpose  : Vue component that manages company rc registration infos and
 *            is used to be validated by the Commune
 *************************************************************/

import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { CompanyRCRegistration } from "models/CompanyForm";
import { mapGetters } from "vuex";
import Calendar from 'primevue/calendar';

@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    })
  }
})
export default class CompanyRCFormComponent extends Vue {
  touched = false;

  @VModel({ type: CompanyRCRegistration }) RCInfos!: CompanyRCRegistration
  /* ------------------- */

  onTouched($event: boolean) {
    this.touched = $event;
    this.$emit("touched", this.RCInfos);
  }

  sendCancel() {
    this.$emit("cancel", true);
    this.touched = false;
  }

  sendSave() {
    this.$emit("save", this.RCInfos);
    this.touched = false;
  }

  beforeMount() {
    if(typeof this.RCInfos.etat == 'string'){
      switch(this.RCInfos.etat){
      case "1":
        this.RCInfos.etat = true;
        break;
      case "0":
        this.RCInfos.etat = false;
        break;
    }
  }
}

}
