
/*************************************************************
 * Author   : R. Fournier
 * E-mail   : fournier.robin@gmail.com
 * Date     : 02.2021
 * Type     : Component
 * Purpose  : Vue component that manages company rc registration infos and
 *            is used to be validated by the Commune
 *************************************************************/

import Vue from "vue";
import Component from "vue-class-component";
import { VModel } from "vue-property-decorator";
import { CompanySiege } from "models/CompanyForm";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters('taxonomies', {
      taxonomies: 'getOneByKey'
    })
  }
})
export default class CompanySiegeFormComponent extends Vue {
  touched = false;

  @VModel({ type: CompanySiege }) siege!: CompanySiege

  /* ------------------- */

  onTouched($event: boolean) {
    this.touched = $event;
    this.$emit("touched", this.siege);
  }

  sendCancel() {
    this.$emit("cancel", true);
    this.touched = false;
  }

  sendSave() {
    this.$emit("save", this.siege);
    this.touched = false;
  }
}
