
import Vue from "vue";
import AuthService from "services/auth.service";
import Component from "vue-class-component";
import MenuItem from "models/MenuItem";
import { Prop, Watch } from "vue-property-decorator";
import NavigationService from "services/nav.service"
import { Route } from "vue-router";

@Component
export default class SidebarComponent extends Vue {

  hover = false;
  endedAnimation = false;
  needsToClose = false;
  currentPath = "";
  logedin = false;

  @Watch('$route', { immediate: true, deep: true })
  onRouteChanged(to: Route, from: Route) {
    this.$forceUpdate()
    NavigationService.get().refresh();
    this.currentPath=to.path
  }

  getItems(){
    return NavigationService.get().getSidebarItems();
  }

  onHover(){
    this.hover = true
    this.needsToClose = false;
    setTimeout(()=>{
      this.endedAnimation = true;
    }, 300)
  }

  getWidth(){
    return  (this.$refs && this.$refs.sidebarItems ? (this.$refs.sidebarItems as Element).clientWidth : 100);
  }

  getNavStyles(){
    return {
       width : (this.hover ? this.getWidth() + "px" : "60px"),
       overflow : (this.hover && this.endedAnimation ? 'visible' : 'hidden')
    }
  }

  checkAuthorisation(item: MenuItem){
    if(item){
      if(item.role && AuthService.userRoleIs(item.role))
        return true;
      else if(item.userCan && AuthService.userCan(item.userCan))
        return true
    }
    return false;
  }

  onLeave(){
    setTimeout(()=>{
        if(this.needsToClose){ // if reenter before 1s, will keep open
          this.endedAnimation = false
          this.hover = false
        }
    }, 1000)
    this.needsToClose = true
  }

  checkCurrentPath(item: MenuItem){
    return MenuItem.hasChildrenWithPath(this.currentPath, item)
  }

  mounted(){
    this.logedin = AuthService.isAuthenticated()
  }
}
